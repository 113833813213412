import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// components and contexts
import SideNav from "./components/sideNav/sideNav";
import { AuthContext } from "./context/authContext";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import createAppTheme from "./theme";
import { Toaster } from "sonner";
import Island from "./context/island/island";
import { Switch, FormControlLabel } from "@mui/material";
import { MainAppBar } from "./components/appBar/appBar";
import useApiService from "./api/apiService";
// import { analytics, logEvent } from "./firebase";

let stripePromise;
try {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
} catch (err) {
  console.error("Failed to load Stripe:", err);
  stripePromise = null;
}

function App() {
  const { token: authToken, team, updateTeam } = useContext(AuthContext);
  const [theme, setTheme] = useState(() => {
    try {
      return createAppTheme();
    } catch (err) {
      console.error("Failed to create theme:", err);
      return {};
    }
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const apiService = useApiService();

  // Fetch and update team data on load for coaches if team exists
  const { data: teamData } = apiService.useTeam(team?.id);
  useEffect(() => {
    try {
      if (teamData) {
        updateTeam(teamData);
      }
    } catch (err) {
      console.error("Failed to update team data:", err);
    }
  }, [teamData, updateTeam]);

  useEffect(() => {
    try {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      const handleChange = (e) => {
        try {
          setIsDarkMode(e.matches);
          setTheme(createAppTheme(e.matches ? "dark" : "light"));
        } catch (err) {
          console.error("Failed to handle theme change:", err);
        }
      };

      handleChange(mediaQuery);
      mediaQuery.addListener(handleChange);

      return () => {
        try {
          mediaQuery.removeListener(handleChange);
        } catch (err) {
          console.error("Failed to remove media query listener:", err);
        }
      };
    } catch (err) {
      console.error("Failed to setup media query:", err);
    }
  }, []);

  const handleThemeToggle = () => {
    try {
      setIsDarkMode(!isDarkMode);
      setTheme(createAppTheme(isDarkMode ? "light" : "dark"));
    } catch (err) {
      console.error("Failed to toggle theme:", err);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <div className="App">
          {authToken && <SideNav />}
          {authToken && <Island />}
          {!authToken && <MainAppBar />}
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 1750,
              success: {
                style: {
                  border: "1px solid green",
                },
              },
            }}
          />
          <Outlet />
          {window.location.hostname.includes("localhost") && (
            <FormControlLabel
              control={
                <Switch checked={isDarkMode} onChange={handleThemeToggle} />
              }
              label="Dark Mode"
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                color: theme.palette.text.primary,
              }}
            />
          )}
        </div>
      </Elements>
    </ThemeProvider>
  );
}

export default App;
