// Feature visibility conditions
const isVisible = {
  ALWAYS: () => true,
  PRO_ONLY: (user) => user?.proTier,
  COACH_ONLY: (user) => user?.userType === 'coach',
  ATHLETE_ONLY: (user) => user?.userType === 'athlete',
  DESKTOP_ONLY: (_, isMobile) => !isMobile,
  MOBILE_ONLY: (_, isMobile) => isMobile,
  HAS_TEAM: (user) => !!user?.team,
  IS_ADMIN: (user) => user?.isAdmin,
};

const combineConditions = (conditions) => (user, isMobile) => {
  return conditions.every(condition => {
    try {
      return condition(user, isMobile);
    } catch (error) {
      console.warn('Condition check failed:', error);
      return false;
    }
  });
};

export const FEATURES = {
  // Athlete Page Features

  // Location Page Features
  routeMapping: {
    banner: {
      id: 'routeMapping',
      title: 'Team Map',
      description: 'Create and manage training routes with interactive mapping tools and pins',
      path: '/locations', 
      icon: '🗺️',
      isVisible: combineConditions([isVisible.COACH_ONLY]),
    },
    tour: {
      steps: [
        {
          title: 'Create Routes',
          content: 'Draw custom routes on the map for team workouts and races.',
        },
        {
          title: 'Distance Tracking',
          content: 'Automatically calculate distances and split markers along your routes.',
        },
        {
          title: 'Pin Locations',
          content: 'Mark important spots like offices, workout locations, and meeting points with custom pins.',
        },
        {
          title: 'Assign Workouts',
          content: 'Link any workout directly to a location for easy team coordination.',
        },
      ],
    },
  },

  // Files Page Features
  fileManagement: {
    banner: {
      id: 'fileManagement',
      title: 'Team File Hub',
      description: 'Centralized file management for team documents, photos, videos and more',
      path: '/team-file/files',
      icon: '📁',
      isVisible: combineConditions([isVisible.COACH_ONLY]),
    },
    tour: {
      steps: [
        {
          title: 'File Organization',
          content: 'Share and organize team content like meet photos, workout guides, and important documents.',
        },
        {
          title: 'File Categories',
          content: 'Create categories with custom permissions to control who can view and upload files.',
        },
        {
          title: 'Multiple File Types',
          content: 'Upload and share a wide variety of files including photos, videos, documents and more.',
        },
      ],
    },
  },

  // Training Plan Features
  workoutBuilder: {
    banner: {
      id: 'workoutBuilder',
      title: 'Workout Builder',
      description: 'Create and manage detailed workout plans for your team',
      path: '/training-plan/workouts',
      icon: '💪',
      isVisible: combineConditions([isVisible.COACH_ONLY]),
    },
    tour: {
      steps: [
        {
          title: 'Smart Workout Library',
          content: 'Build a reusable library of workouts that automatically adjust based on athlete PRs, goals and fitness levels.',
        },
        {
          title: 'Intelligent Calculations',
          content: 'Auto-calculate distances and sprint workouts customized to each athlete\'s performance metrics.',
        },
        {
          title: 'Workout Resources',
          content: 'Attach training guides, videos and other files to workouts for quick reference during practice.',
        },
        {
          title: 'Dynamic Adjustments',
          content: 'Workouts automatically update when athletes improve their PRs, set new goals, or fitness levels change.',
        },
        {
          title: 'Flexible Assignment',
          content: 'Add workouts to training plans and assign to specific athletes or groups with optional time/location.',
        },
        {
          title: 'AI Workout Creation',
          content: 'Save time by describing your workout in plain text and let AI generate the details automatically.',
        },
      ],
    },
  },

  // Calculator Features
  performanceTools: {
    banner: {
      id: 'performanceTools',
      title: 'Performance Tools',
      description: 'Access advanced calculators and tools for training optimization',
      path: '/calculators',
      icon: '🧮',
      isVisible: combineConditions([isVisible.COACH_ONLY]),
    },
    tour: {
      steps: [
        {
          title: 'Training Calculators',
          content: 'Use specialized calculators for pace, splits, and much more.',
        },
        {
          title: 'Performance Metrics',
          content: 'Calculate key performance indicators and training targets.',
        },
        {
          title: 'Custom Formulas',
          content: 'Access sport-specific formulas and conversion tools.',
        },
      ],
    },
  },

  // Roster Management Features
  rosterManagement: {
    banner: {
      id: 'rosterManagement',
      title: 'Team Management',
      description: 'Comprehensive tools for managing your team roster and groups',
      path: '/roster',
      icon: '👥',
      isVisible: combineConditions([isVisible.COACH_ONLY]),
    },
    tour: {
      steps: [
        {
          title: 'Roster Overview',
          content: 'Manage athletes, coaches, and training groups in one place.',
        },
        {
          title: 'Group Management',
          content: 'Create and organize training groups for efficient team management.',
        },
        {
          title: 'Quick Actions',
          content: 'Perform common tasks like adding athletes or updating information.',
        },
      ],
    },
  },

  // Keep existing features
  goals: {
    banner: {
      id: 'goals',
      title: 'Track Your Goals',
      description: 'Set and monitor your athletic goals with our new goals tracking system',
      path: '/home',
      icon: '🎯',
      isVisible: isVisible.ALWAYS,
    },
    tour: {
      steps: [
        {
          title: 'Two Types of Goals',
          content: 'You can set Daily Goals for building consistent habits, and Long-term Goals for bigger achievements you want to work towards.',
        },
        {
          title: 'Daily Goals',
          content: 'Daily goals help build consistent habits. Set goals you want to achieve every day, like drinking water or completing a workout. Build streaks by completing them consistently!',
        },
        {
          title: 'Long-term Goals', 
          content: 'Long-term goals are bigger achievements you\'re working towards. Set a target date and track your progress over time.',
        },
        {
          title: 'Track Your Progress',
          content: 'Check off completed goals to track your progress. For daily goals, you\'ll see your streak count. For long-term goals, you\'ll see days remaining until your target date.',
        }
      ],
    },
  },
};

// Helper functions remain unchanged
export const getAvailableFeatures = (user, isMobile) => {
  try {
    return Object.values(FEATURES)
      .filter(feature => {
        try {
          return feature.banner.isVisible(user, isMobile);
        } catch (error) {
          console.warn(`Feature visibility check failed for ${feature.banner.id}:`, error);
          return false;
        }
      })
      .map(feature => feature.banner);
  } catch (error) {
    console.error('Failed to get available features:', error);
    return [];
  }
};

export const getTourSteps = (featureId) => {
  return FEATURES[featureId]?.tour.steps || [];
}; 