import React, { useRef, useState, useEffect } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  IconButton,
  MobileStepper,
  Link,
} from "@mui/material";
import {
  Timeline,
  EventNote,
  Calculate,
  Groups,
  FolderShared,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { motion, useInView, AnimatePresence } from "framer-motion";
import { BoxReveal } from "../../../../components/magicUI";

const FeatureCard = ({
  icon,
  title,
  description,
  beta,
  comingSoon,
  isMobile,
  link,
  linkText,
}) => (
  <Card
    component={motion.div}
    whileHover={{ scale: 1.03 }}
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      boxShadow: 3,
    }}
  >
    <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
      <IconButton
        color="primary"
        sx={{
          mb: 2,
          "& .MuiSvgIcon-root": {
            fontSize: isMobile ? "1.8rem" : "2.4rem",
          },
          "&:hover": { backgroundColor: "transparent" },
          cursor: "default",
        }}
        disableRipple
      >
        {icon}
      </IconButton>
      <Typography
        variant="h6"
        component="h3"
        fontWeight="bold"
        gutterBottom
        sx={{
          fontSize: isMobile ? "1rem" : "1.25rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: isMobile ? "0.875rem" : "1rem",
        }}
      >
        {description}
      </Typography>
      {beta && (
        <Typography
          variant="caption"
          sx={{
            mt: 2,
            display: "inline-block",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            fontSize: isMobile ? "0.7rem" : "0.8rem",
          }}
        >
          Beta
        </Typography>
      )}
      {comingSoon && (
        <Typography
          variant="caption"
          sx={{
            mt: 2,
            display: "inline-block",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
            fontSize: isMobile ? "0.7rem" : "0.8rem",
          }}
        >
          Coming Soon
        </Typography>
      )}
      {link && (
        <Box sx={{ mt: 2 }}>
          <Link 
            href={link}
            sx={{
              fontSize: isMobile ? "0.8rem" : "0.9rem",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline"
              }
            }}
          >
            {linkText}
          </Link>
        </Box>
      )}
    </CardContent>
  </Card>
);

const Welcome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true, margin: "-100px" });
  const [activeStep, setActiveStep] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const features = [
    {
      icon: <EventNote fontSize="large" />,
      title: "Dynamic Training Plans",
      description:
        "Create training plans that automatically adjust paces based on athlete fitness, PRs, and goals. No more complicated spreadsheets.",
    },
    {
      icon: <Calculate fontSize="large" />,
      title: "Training Calculators",
      description:
        "Powerful calculators to help determine training paces, race predictions, and workout intensities.",
      link: "/calculators",
      linkText: "Try them out"
    },
    {
      icon: <Groups fontSize="large" />,
      title: "Roster Management",
      description:
        "Easily manage your team roster, organize athletes into training groups, and track alumni.",
    },
    {
      icon: <FolderShared fontSize="large" />,
      title: "File Management",
      description:
        "Organize and share team files with customizable categories and permissions. Attach files directly to workouts for easy access.",
    },
    {
      icon: <Timeline fontSize="large" />,
      title: "Comprehensive Training Log",
      description:
        "Effortlessly track progress over time with our user-friendly interface.",
      comingSoon: true,
    },
  ];

  useEffect(() => {
    let timer;
    if (isMobile && autoPlay) {
      timer = setInterval(() => {
        setActiveStep((prevStep) => (prevStep + 1) % features.length);
      }, 5000); // Change slide every 5 seconds
    }
    return () => clearInterval(timer);
  }, [isMobile, autoPlay, features.length]);

  const handleNext = () => {
    setAutoPlay(false);
    setActiveStep((prevStep) => (prevStep + 1) % features.length);
  };

  const handleBack = () => {
    setAutoPlay(false);
    setActiveStep((prevStep) =>
      prevStep === 0 ? features.length - 1 : prevStep - 1
    );
  };

  const dark_png = "https://imgur.com/dxh3xWt.png";
  const light_png = "https://imgur.com/LpAFStO.png";

  return (
    <Box
      component="main"
      mb={isMobile ? 0 : -40}
      mt={isMobile ? 0 : -10}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BoxReveal>
          <Typography
            variant="h1"
            component="h1"
            fontWeight="800"
            color="text.primary"
            sx={{
              mb: 3,
              fontSize: { xs: "2rem", sm: "3rem" },
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textAlign: "center",
              width: "100%",
            }}
          >
            Save up to 15 hours per month with our suite of custom tools
          </Typography>
        </BoxReveal>

        <BoxReveal>
          <Typography
            variant="h2"
            component="h2"
            fontWeight="600"
            color="text.secondary"
            sx={{
              mb: 4,
              fontSize: { xs: "1.5rem", sm: "2rem" },
              textAlign: "center",
              width: "100%",
            }}
          >
            Designed by Coaches, for Coaches
          </Typography>
        </BoxReveal>

        {isMobile ? (
          <Box sx={{ width: "100%", mb: 8 }}>
            <AnimatePresence mode="wait">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.3 }}
                style={{ padding: "0 16px" }}
              >
                <FeatureCard {...features[activeStep]} isMobile={isMobile} />
              </motion.div>
            </AnimatePresence>
            <MobileStepper
              steps={features.length}
              position="static"
              activeStep={activeStep}
              sx={{
                maxWidth: 400,
                flexGrow: 1,
                margin: "20px auto 0",
                background: "transparent",
              }}
              nextButton={
                <IconButton size="small" onClick={handleNext}>
                  <KeyboardArrowRight />
                </IconButton>
              }
              backButton={
                <IconButton size="small" onClick={handleBack}>
                  <KeyboardArrowLeft />
                </IconButton>
              }
            />
          </Box>
        ) : (
          <Grid container spacing={4} justifyContent="center" sx={{ mb: 8 }}>
            {features.map((feature, index) => (
              <Grid item xs={6} md={4} key={index}>
                <FeatureCard {...feature} isMobile={isMobile} />
              </Grid>
            ))}
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: 2,
            mb: 8,
          }}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{
              fontWeight: "700",
              px: { xs: 2, sm: 4 },
              py: 1.5,
              borderRadius: 2,
              fontSize: { xs: "1rem", sm: "1.1rem" },
              width: { xs: "100%", sm: "auto" },
              mb: { xs: 2, sm: 0 },
            }}
            component={motion.button}
            whileHover={{ scale: 1.05 }}
            onClick={() => navigate("/login/create-account")}
          >
            Start simplifying your coaching today!
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            sx={{
              fontWeight: "700",
              px: { xs: 2, sm: 4 },
              py: 1.5,
              borderRadius: 2,
              fontSize: { xs: "1rem", sm: "1.1rem" },
              width: { xs: "100%", sm: "auto" },
            }}
            component={motion.button}
            whileHover={{ scale: 1.05 }}
            onClick={() => navigate("/login")}
          >
            Already a member? Log In
          </Button>
        </Box>
      </Container>

      <Box
        ref={ref}
        sx={{
          position: "relative",
          width: "100%",
          mt: 3,
          opacity: 0,
          animation: inView ? "fadeUp 0.6s ease-out forwards" : "none",
          animationDelay: "400ms",
          perspective: "2000px",
          "&::after": {
            content: '""',
            position: "absolute",
            inset: 0,
            zIndex: 50,
            background: `linear-gradient(to top, ${theme.palette.background.default} 30%, transparent)`,
          },
          "@keyframes fadeUp": {
            "0%": { opacity: 0, transform: "translateY(20px)" },
            "100%": { opacity: 1, transform: "translateY(0)" },
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            borderRadius: "16px",
            border: `2px solid ${theme.palette.primary.main}`,
          }}
        >
          <Box
            component="img"
            src={theme.palette.mode === "dark" ? dark_png : light_png}
            alt="Track Pace Pro coaching platform interface showcase"
            sx={{
              display: "block",
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "14px",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { Welcome };
