import React from 'react';
import { Box, Button, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';
import { getAvailableFeatures } from '../../constants/featureTours';

const FeatureBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { user, userType, proTier, team } = useContext(AuthContext);
  const [isVisible, setIsVisible] = React.useState(true);

  const [availableFeatures] = React.useState(() => {
    const completedFeatures = JSON.parse(localStorage.getItem('completedFeatures') || '[]');
    const reminders = JSON.parse(localStorage.getItem('feature_reminders') || '{}');
    const currentTime = new Date().getTime();

    const userContext = {
      ...user,
      userType,
      proTier,
      team,
    };

    return getAvailableFeatures(userContext, isMobile).filter(f => {
      const reminderTime = reminders[f.id];
      return !completedFeatures.includes(f.id) && 
             (!reminderTime || currentTime > reminderTime);
    });
  });

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % availableFeatures.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + availableFeatures.length) % availableFeatures.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    trackTouch: true,
  });

  // Early returns after all hooks
  if (!isVisible || location.pathname === '/' || location.pathname === '/home') {
    return null;
  }

  if (!availableFeatures.length) return null;

  const currentFeature = availableFeatures[currentIndex];

  const handleLearnMore = () => {
    navigate(currentFeature.path, {
      state: { showFeatureTour: true, featureId: currentFeature.id }
    });
  };

  const handleRemindLater = () => {
    const reminders = JSON.parse(localStorage.getItem('feature_reminders') || '{}');
    const reminderTime = new Date();
    reminderTime.setDate(reminderTime.getDate() + 1);
    reminderTime.setHours(9, 0, 0, 0);
    
    reminders[currentFeature.id] = reminderTime.getTime();
    localStorage.setItem('feature_reminders', JSON.stringify(reminders));
    
    // Force a re-render instead of page reload
    setCurrentIndex(0);
    // Re-initialize available features
    const completedFeatures = JSON.parse(localStorage.getItem('completedFeatures') || '[]');
    const currentTime = new Date().getTime();

    const userContext = {
      ...user,
      userType,
      proTier,
      team,
    };

    const newFeatures = getAvailableFeatures(userContext, isMobile).filter(f => {
      const reminderTime = reminders[f.id];
      return !completedFeatures.includes(f.id) && 
             (!reminderTime || currentTime > reminderTime);
    });

    if (newFeatures.length === 0) {
      setIsVisible(false);
    }
  };

  // Add check for training plan schedule page
  const isTrainingPlanSchedule = location.pathname === '/training-plan/schedule';

  return (
    <Box
      sx={{
        position: 'relative',
        mx: isMobile ? 1 : 4,
        mb: 1,
        mt: isMobile ? 2 : 1,
        // Add extra right padding for training plan schedule page
        ...(isTrainingPlanSchedule && !isMobile && {
          mr: 16, // Increase right margin for sidebar
        }),
      }}
    >
      <Box {...(isMobile ? handlers : {})}>
        <Paper
          elevation={2}
          sx={{
            p: isMobile ? 1 : 1.5,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'stretch' : 'center',
            justifyContent: 'space-between',
            backgroundColor: `${theme.palette.primary.main}CC`,
            color: theme.palette.primary.contrastText,
            gap: isMobile ? 1 : 0,
            cursor: isMobile ? 'grab' : 'default',
            '&:active': {
              cursor: isMobile ? 'grabbing' : 'default',
            },
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1.5,
            width: isMobile ? '100%' : 'auto',
          }}>
            <Typography 
              variant="h2" 
              sx={{ 
                fontSize: isMobile ? '1.25rem' : '1.5rem',
                minWidth: isMobile ? '1.5rem' : '2rem',
                color: theme.palette.primary.contrastText,
              }}
            >
              {currentFeature.icon}
            </Typography>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: isMobile ? '0.875rem' : '1rem',
                  lineHeight: 1.2,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {currentFeature.title}
              </Typography>
              <Typography 
                variant="body2"
                sx={{
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  color: theme.palette.primary.contrastText,
                  opacity: 0.9,
                }}
              >
                {currentFeature.description}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            width: isMobile ? '100%' : 'auto',
            flexDirection: isMobile ? 'row' : 'row',
            justifyContent: isMobile ? 'stretch' : 'flex-end',
            mt: isMobile ? 1 : 0,
          }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLearnMore}
              fullWidth={isMobile}
              size="small"
              sx={{
                minWidth: isMobile ? 'auto' : '100px',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Learn More
            </Button>
            <Button
              variant="outlined"
              onClick={handleRemindLater}
              fullWidth={isMobile}
              size="small"
              sx={{ 
                minWidth: isMobile ? 'auto' : '100px',
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.primary.contrastText,
                '&:hover': {
                  borderColor: theme.palette.primary.contrastText,
                  backgroundColor: `${theme.palette.primary.contrastText}10`,
                }
              }}
            >
              Later
            </Button>
          </Box>
        </Paper>
      </Box>
      {availableFeatures.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            onClick={handlePrev}
            sx={{
              display: isMobile ? 'none' : 'flex',
              minWidth: 'auto',
              p: 1,
              width: 36,
              height: 36,
              borderRadius: '50%',
              backgroundColor: theme.palette.background.paper,
              boxShadow: 1,
              color: theme.palette.primary.main,
            }}
          >
            <KeyboardArrowLeftIcon />
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            {availableFeatures.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: index === currentIndex ? 
                    (isMobile ? 16 : 20) : 
                    (isMobile ? 6 : 8),
                  height: isMobile ? 6 : 8,
                  borderRadius: '12px',
                  backgroundColor: index === currentIndex ? 
                    theme.palette.primary.main : 
                    `${theme.palette.primary.main}66`,
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </Box>

          <Button
            onClick={handleNext}
            sx={{
              display: isMobile ? 'none' : 'flex',
              minWidth: 'auto',
              p: 1,
              width: 36,
              height: 36,
              borderRadius: '50%',
              backgroundColor: theme.palette.background.paper,
              boxShadow: 1,
              color: theme.palette.primary.main,
            }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FeatureBanner; 