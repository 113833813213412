import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import RosterIcon from "@mui/icons-material/People";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import FormTeam from "../teamUtils/formTeam";
import { FormUser, FormPassword } from "../user";
import SubscriptionForm from "../stripe/subscriptionForm";

const UserCard = () => {
  const { userType, user, team } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isTeamFormOpen, setIsTeamFormOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isPasswordFormOpen, setIsPasswordFormOpen] = useState(false);
  const [isSubscriptionFormOpen, setIsSubscriptionFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUserCardClick = () => {
    if (userType === "athlete") {
      navigate(`/roster/athletes/${user.id}`);
    }
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTeamFormOpen = () => {
    setIsTeamFormOpen(true);
    handleMenuClose();
  };

  const handleTeamFormClose = () => {
    setIsTeamFormOpen(false);
  };

  const handleUserFormOpen = () => {
    setIsUserFormOpen(true);
    handleMenuClose();
  };

  const handleUserFormClose = () => {
    setIsUserFormOpen(false);
  };

  const handlePasswordFormOpen = () => {
    setIsPasswordFormOpen(true);
    handleMenuClose();
  };

  const handlePasswordFormClose = () => {
    setIsPasswordFormOpen(false);
  };

  const handleSubscriptionFormOpen = () => {
    setIsSubscriptionFormOpen(true);
    handleMenuClose();
  };

  const handleSubscriptionFormClose = () => {
    setIsSubscriptionFormOpen(false);
  };

  const handleTeamNameClick = (event) => {
    event.stopPropagation();
    if (userType === "coach") {
      setIsTeamFormOpen(true);
    }
  };

  return (
    <>
      <Box
        onClick={handleUserCardClick}
        sx={{
          mx: 1,
          p: 1.5,
          mb: 1,
          borderRadius: 2,
          cursor: userType === "athlete" ? "pointer" : "default",
          backgroundColor: "primary.main",
          boxShadow: 1,
          "&:hover":
            userType === "athlete"
              ? {
                  backgroundColor: "primary.dark",
                  boxShadow: 2,
                }
              : {},
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={team?.url}
            sx={{
              width: 40,
              height: 40,
              bgcolor: "primary.light",
              mr: 1,
              border: 1,
              borderColor: "primary.contrastText",
            }}
          >
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </Avatar>
          <Box
            sx={{
              flexGrow: 1,
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "primary.contrastText",
                }}
              >
                {user?.firstName} {user?.lastName}
              </Typography>
              <IconButton
                size="small"
                onClick={handleMenuClick}
                sx={{
                  ml: 1,
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </Box>
            {team?.name && (
              <Typography
                variant="caption"
                onClick={handleTeamNameClick}
                sx={{
                  color: "primary.contrastText",
                  opacity: 0.85,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: userType === "coach" ? "pointer" : "default",
                  "&:hover":
                    userType === "coach"
                      ? {
                          opacity: 1,
                        }
                      : {},
                }}
              >
                <RosterIcon sx={{ fontSize: 14, mr: 0.5, flexShrink: 0 }} />
                {team.name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userType === "coach" && (
          <MenuItem onClick={handleTeamFormOpen}>Update Team</MenuItem>
        )}
        <MenuItem onClick={handleUserFormOpen}>Update User Info</MenuItem>
        <MenuItem onClick={handlePasswordFormOpen}>Update Password</MenuItem>
        {userType === "coach" && (
          <MenuItem onClick={handleSubscriptionFormOpen}>Manage Subscription</MenuItem>
        )}
      </Menu>

      {userType === "coach" && (
        <FormTeam
          open={isTeamFormOpen}
          handleClose={handleTeamFormClose}
          onTeamUpdated={() => {}}
          team={team}
        />
      )}
      <FormUser open={isUserFormOpen} handleClose={handleUserFormClose} />
      <FormPassword
        open={isPasswordFormOpen}
        handleClose={handlePasswordFormClose}
      />
      {userType === "coach" && (
        <SubscriptionForm
          open={isSubscriptionFormOpen}
          onClose={handleSubscriptionFormClose}
          initialIsYearly={false}
        />
      )}
    </>
  );
};

export default UserCard;
