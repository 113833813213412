import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  IconButton,
  Button,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useApiService from "../../../../../api/apiService";
import AthletesPrs from "../../../../../components/pods/athletePod/_components/pr/athletesPrs";
import AthletesGoals from "../../../../../components/pods/athletePod/_components/goal/athletesGoals";
import ComponentWrapper from "../../../../../components/utils/wrappers/componentWrapper";
import FormAthlete from "../../../../../components/pods/athletePod/_components/formAthlete";
import { CheckGoals } from "../../../../../pages/home/_components/checkGoals/checkGoals";
import { AuthContext } from "../../../../../context/authContext";
import Form from "../../../../../components/form/form";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import CakeIcon from "@mui/icons-material/Cake";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const AthletePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { athleteId } = useParams();
  const { useAthlete, useUpdateAthlete } = useApiService();
  const [editOpen, setEditOpen] = React.useState(false);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const { userType } = useContext(AuthContext);

  const { data: athlete, isLoading, error, refetch } = useAthlete(athleteId);
  const updateAthlete = useUpdateAthlete();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">Error Loading Athlete Data</Typography>
      </Box>
    );
  }

  const isDistanceAthlete = athlete?.athleteTypes?.includes("distance");

  const handleInviteSubmit = (formData) => {
    updateAthlete.mutate(
      {
        id: athlete.id,
        data: { email: formData.email },
      },
      {
        onSuccess: () => {
          setOpenInviteDialog(false);
          refetch();
        },
      }
    );
  };

  const formatLastSeen = (lastSeen) => {
    if (!lastSeen) return "Never";
    const date = new Date(lastSeen);
    return date.toLocaleDateString();
  };

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return minutes > 0
      ? `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      : `${remainingSeconds}`;
  };

  return (
    <>
      <Box sx={{ p: { xs: 0.5, sm: 2, md: 3 } }}>
        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/roster/athletes")}
          sx={{
            mb: 1,
            color: "text.secondary",
            "&:hover": { bgcolor: "action.hover" },
            fontSize: { xs: "0.813rem", sm: "0.875rem" },
          }}
        >
          Back to Roster
        </Button>

        {/* Main Profile Card */}
        <Paper
          elevation={2}
          sx={{
            borderRadius: 2,
            background: theme.palette.background.paper,
            overflow: 'hidden',
            mb: 2,
          }}
        >
          {/* Header Section with Background */}
          <Box
            sx={{
              position: 'relative',
              bgcolor: 'primary.main',
              pt: { xs: 3, sm: 4 },
              pb: { xs: 7, sm: 8 },
              px: { xs: 2, sm: 3 },
            }}
          >
            <IconButton
              onClick={() => setEditOpen(true)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "rgba(255,255,255,0.1)",
                color: "white",
                "&:hover": { bgcolor: "rgba(255,255,255,0.2)" },
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>

          {/* Avatar and Basic Info */}
          <Box
            sx={{
              mt: { xs: -6, sm: -7 },
              px: { xs: 2, sm: 3 },
              pb: { xs: 2, sm: 3 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Avatar
                sx={{
                  width: { xs: 80, sm: 120 },
                  height: { xs: 80, sm: 120 },
                  fontSize: { xs: "1.5rem", sm: "2.5rem" },
                  bgcolor: 'primary.dark',
                  border: '4px solid white',
                  boxShadow: 1,
                }}
              >
                {athlete?.firstName?.[0]}
                {athlete?.lastName?.[0]}
              </Avatar>
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: { xs: "1.25rem", sm: "1.5rem" },
                }}
              >
                {athlete?.firstName} {athlete?.lastName}
              </Typography>
              {isDistanceAthlete && (
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ fontWeight: 600 }}
                  >
                    {athlete?.fScore || "-"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontWeight: 500 }}
                  >
                    F Score
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Info Grid */}
            <Grid container spacing={2} sx={{ maxWidth: 900, mx: 'auto' }}>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<EmailIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Email"
                  value={athlete?.email}
                  href={athlete?.email ? `mailto:${athlete?.email}` : null}
                  action={
                    userType === "coach" &&
                    !athlete.invited &&
                    !athlete?.email && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setOpenInviteDialog(true)}
                        sx={{ mt: 1, fontSize: '0.75rem' }}
                      >
                        Invite
                      </Button>
                    )
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<PhoneIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Phone"
                  value={athlete?.phone}
                  href={athlete?.phone ? `tel:${athlete?.phone}` : null}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<SchoolIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Grade"
                  value={athlete?.grade}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<CakeIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Birth Date"
                  value={athlete?.birthDate}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<PersonIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Gender"
                  value={athlete?.gender}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  icon={<DirectionsRunIcon sx={{ fontSize: '1.2rem' }} />}
                  label="Type"
                  value={athlete?.athleteTypes?.join(", ")}
                />
              </Grid>
              {userType === "coach" && (
                <Grid item xs={6} sm={4}>
                  <InfoCard
                    icon={<AccessTimeIcon sx={{ fontSize: '1.2rem' }} />}
                    label="Last Seen"
                    value={formatLastSeen(athlete?.lastSeen)}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>

        {/* Stats Sections */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ComponentWrapper sx={{ p: { xs: 1.5, sm: 3 } }}>
              <Typography
                variant="h6"
                sx={{
                  mb: { xs: 1, sm: 3 },
                  fontWeight: 500,
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                Personal Records
              </Typography>
              <AthletesPrs athlete={athlete} />
            </ComponentWrapper>
          </Grid>

          <Grid item xs={12} md={6}>
            <ComponentWrapper sx={{ p: { xs: 1.5, sm: 3 } }}>
              <Typography
                variant="h6"
                sx={{
                  mb: { xs: 2, sm: 3 },
                  fontWeight: 500,
                  fontSize: { xs: "1.125rem", sm: "1.25rem" },
                }}
              >
                Performance Goals
              </Typography>
              <AthletesGoals athlete={athlete} />
            </ComponentWrapper>
          </Grid>

          <Grid item xs={12}>
            <ComponentWrapper>
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                Process Goals
              </Typography>
              <CheckGoals athleteId={athleteId} />
            </ComponentWrapper>
          </Grid>

          {isDistanceAthlete && athlete?.fScore && athlete?.paceChart && (
            <>
              <Grid item xs={12} md={6}>
                <ComponentWrapper>
                  <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                    Race Predictions
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Distance</TableCell>
                          <TableCell>Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(athlete.paceChart.prediction).map(
                          ([distance, time]) => (
                            <TableRow key={distance}>
                              <TableCell>{distance}</TableCell>
                              <TableCell>{convertSecondsToTime(time)}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ComponentWrapper>
              </Grid>

              <Grid item xs={12} md={6}>
                <ComponentWrapper>
                  <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                    Pace Chart
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Pace Type</TableCell>
                          {[
                            "mile",
                            "1600",
                            "1000",
                            "800",
                            "600",
                            "400",
                            "300",
                            "200",
                            "100",
                          ].map((distance) => (
                            <TableCell key={distance}>{distance}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(athlete.paceChart.training).map(
                          ([paceType, data]) => (
                            <TableRow key={paceType}>
                              <TableCell sx={{ textTransform: "capitalize" }}>
                                {paceType.replace(/_/g, " ")}
                              </TableCell>
                              {[
                                "mile",
                                "1600",
                                "1000",
                                "800",
                                "600",
                                "400",
                                "300",
                                "200",
                                "100",
                              ].map((distance) => (
                                <TableCell key={distance}>
                                  {convertSecondsToTime(data.values[distance])}
                                </TableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ComponentWrapper>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <FormAthlete
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        athlete={athlete}
        refetch={refetch}
      />

      <Form
        open={openInviteDialog}
        onClose={() => setOpenInviteDialog(false)}
        fields={[
          {
            name: "email",
            label: "Email",
            type: "email",
          },
          {
            type: "custom",
            render: () => (
              <Box mt={2}>
                <Typography variant="body2" color="text.secondary">
                  Please inform the athlete to check their spam folder and mark
                  the email as safe. This ensures they receive important
                  communications and future notifications.
                </Typography>
              </Box>
            ),
          },
        ]}
        onSubmit={handleInviteSubmit}
        title="Invite Athlete"
        defaultValues={{ email: athlete?.email || "" }}
      />
    </>
  );
};

const InfoCard = ({ icon, label, value, action, href }) => (
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
      {icon}
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ fontWeight: 500 }}
      >
        {label}
      </Typography>
    </Box>
    <Box
      sx={{
        bgcolor: 'background.default',
        borderRadius: 1,
        p: 1,
        minHeight: '32px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {href ? (
        <Link
          href={href}
          underline="hover"
          sx={{
            color: 'text.primary',
            fontSize: { xs: '0.875rem', sm: '0.9rem' },
            fontWeight: 400,
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            '&:hover': {
              color: 'primary.main',
            }
          }}
        >
          {value || "Not Provided"}
        </Link>
      ) : (
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: '0.875rem', sm: '0.9rem' },
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            color: value ? 'text.primary' : 'text.secondary',
          }}
        >
          {value || "Not Provided"}
        </Typography>
      )}
    </Box>
    {action && (
      <Box sx={{ mt: 1 }}>
        {action}
      </Box>
    )}
  </Box>
);

export default AthletePage;
