import React, { useState, useEffect, useContext } from "react";
import useApiService from "../../../../api/apiService";
import { toast } from "sonner";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Checkbox,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";
import ComponentWrapper from "../../../../components/utils/wrappers/componentWrapper";
import MenuIcon from "../../../../components/utils/menuIcon/menuIcon";
import Form from "../../../../components/form/form";
import { AuthContext } from "../../../../context/authContext";

const NoGoalsPlaceholder = ({ goalType, onAddClick }) => (
  <Box
    sx={{
      textAlign: 'center',
      py: 3,
      px: 2,
      backgroundColor: 'action.hover',
      borderRadius: 2,
      border: '1px dashed',
      borderColor: 'divider',
    }}
  >
    <Typography variant="body2" color="text.secondary" paragraph>
      {goalType === "daily" 
        ? "Daily goals help build consistent habits. Set goals you want to achieve every day, like drinking water or completing a workout."
        : "Long-term goals are bigger achievements you're working towards. Set a target date and track your progress over time."}
    </Typography>
    <Button 
      variant="outlined" 
      color="primary" 
      startIcon={<AddIcon />} 
      size="small"
      onClick={() => onAddClick(goalType)}
    >
      Add Goal
    </Button>
  </Box>
);

const CheckGoals = ({ athleteId }) => {
  const { user } = useContext(AuthContext);
  const [goals, setGoals] = useState({ daily: [], long: [] });
  const [formOpen, setFormOpen] = useState(false);
  const [editingGoal, setEditingGoal] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    goalId: null,
  });
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const {
    useCheckGoals,
    useCreateCheckGoal,
    useUpdateCheckGoal,
    useDeleteCheckGoal,
    useClickCheckGoal,
  } = useApiService();

  const canEdit = !athleteId || (user && Number(athleteId) === Number(user.id));

  const { data: fetchedGoals, isLoading, isError } = useCheckGoals(athleteId);
  const createGoalMutation = useCreateCheckGoal();
  const updateGoalMutation = useUpdateCheckGoal();
  const deleteGoalMutation = useDeleteCheckGoal();
  const clickGoalMutation = useClickCheckGoal();

  useEffect(() => {
    if (fetchedGoals) {
      const sortedGoals = {
        daily: fetchedGoals
          .filter((goal) => goal.goalType === "daily" && goal.active)
          .sort((a, b) => a.id - b.id),
        long: fetchedGoals
          .filter((goal) => goal.goalType === "long" && goal.active)
          .sort((a, b) => a.id - b.id),
      };
      setGoals(sortedGoals);
    }
  }, [fetchedGoals]);

  const handleCreateGoal = (data) => {
    if (!canEdit) return;
    createGoalMutation.mutate(
      {
        ...data,
        active: true,
        athleteId: athleteId
      },
      {
        onSuccess: () => {
          setFormOpen(false);
          toast.success("Goal created successfully");
        },
      }
    );
  };

  const handleUpdateGoal = (id, updates) => {
    if (!canEdit) return;
    updateGoalMutation.mutate(
      { id, data: { ...updates, athleteId } },
      {
        onSuccess: () => {
          setFormOpen(false);
          setEditingGoal(null);
          toast.success("Goal updated successfully");
        },
      }
    );
  };

  const handleDeleteGoal = (id) => {
    if (!canEdit) return;
    setDeleteConfirmation({ open: true, goalId: id });
    handleCloseMenu();
  };

  const confirmDeleteGoal = () => {
    if (deleteConfirmation.goalId && canEdit) {
      deleteGoalMutation.mutate(deleteConfirmation.goalId, {
        onSuccess: () => {
          setDeleteConfirmation({ open: false, goalId: null });
          toast.success("Goal deleted successfully");
        },
      });
    }
  };

  const handleClickGoal = (goal) => {
    if (!canEdit) return;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayString = today.toISOString().split('T')[0]; // Format: 2024-10-10
    clickGoalMutation.mutate(
      { id: goal.id, date: todayString },
      {
        onSuccess: (updatedGoal) => {
          const updatedGoals = { ...goals };
          const goalType = updatedGoal.goalType;
          const index = updatedGoals[goalType].findIndex(
            (g) => g.id === updatedGoal.id
          );
          if (index !== -1) {
            updatedGoals[goalType][index] = updatedGoal;
            setGoals(updatedGoals);
          }
          toast.success("Goal updated successfully");
        },
      }
    );
  };

  const calculateStreak = (dates) => {
    if (!dates || dates.length === 0) return 0;
    let streak = 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    for (let i = 0; i < 365; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);
      const currentDateString = currentDate.toISOString().split('T')[0];
      
      if (dates.includes(currentDateString)) {
        streak++;
      } else {
        break;
      }
    }
    
    return streak;
  };

  const getStreakEmoji = (streak) => {
    if (streak === 0) return "🪵";
    if (streak < 3) return "🔥";
    if (streak < 7) return "🔥🔥";
    if (streak < 14) return "🔥🔥🔥";
    if (streak < 30) return "🔥🔥🔥🔥";
    return "🔥🔥🔥🔥🔥";
  };

  const isCompletedToday = (dates) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayString = today.toISOString().split('T')[0];
    return dates && dates.includes(todayString);
  };

  const handleOpenMenu = (event, goal) => {
    if (!canEdit) return;
    setMenuAnchor(event.currentTarget);
    setSelectedGoal(goal);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
    setSelectedGoal(null);
  };

  const handleEditGoal = () => {
    setEditingGoal(selectedGoal);
    setFormOpen(true);
    handleCloseMenu();
  };

  const calculateDaysUntil = (goalDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const targetDate = new Date(goalDate);
    const timeDiff = targetDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  };

  const fields = [
    {
      name: "goalType",
      type: "custom",
      render: (formData, handleChange) => (
        <ToggleButtonGroup
          value={formData.goalType}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) {
              handleChange("goalType", newValue);
            }
          }}
          aria-label="Goal Type"
          size="small"
          fullWidth
        >
          <ToggleButton value="daily" aria-label="Daily">
            Daily
          </ToggleButton>
          <ToggleButton value="long" aria-label="Long-term">
            Long-term
          </ToggleButton>
        </ToggleButtonGroup>
      ),
      required: true,
    },
    { name: "text", label: "Goal", type: "text", required: true },
    {
      name: "goalDate",
      label: "Goal Date",
      type: "date",
      hidden: (formData) => formData.goalType !== "long",
    },
  ];

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError)
    return <Typography color="error">Error loading goals</Typography>;

  const renderGoalList = (goalType) => (
    <Box>
      <Typography variant="h6" gutterBottom>
        {goalType === "daily" ? "Daily Goals" : "Long-term Goals"}
      </Typography>
      {goals[goalType].length === 0 ? (
        <NoGoalsPlaceholder 
          goalType={goalType} 
          onAddClick={(type) => {
            setEditingGoal(null);
            setFormOpen(true);
            fields.find(f => f.name === "goalType").defaultValue = type;
          }} 
        />
      ) : (
        <List>
          {goals[goalType].map((goal) => (
            <Box
              key={goal.id}
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                px: 2,
                mb: 1,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <ListItem
                disablePadding
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleClickGoal(goal)}
                    checked={
                      goalType === "daily"
                        ? isCompletedToday(goal.dates)
                        : goal.completed
                    }
                    disabled={!canEdit}
                  />
                }
              >
                {canEdit && (
                  <IconButton
                    onClick={(event) => handleOpenMenu(event, goal)}
                    sx={{ mr: 1, p: 0.5 }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                <ListItemText
                  primary={
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        fontWeight: "medium",
                        wordBreak: "break-word",
                        overflowWrap: "break-word"
                      }}
                    >
                      {goal.text}
                    </Typography>
                  }
                  secondary={
                    goalType === "daily" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.secondary.main,
                            mr: 0.5,
                          }}
                        >
                          {getStreakEmoji(calculateStreak(goal.dates))}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) =>
                              calculateStreak(goal.dates) > 0
                                ? theme.palette.secondary.main
                                : theme.palette.text.disabled,
                            display: calculateStreak(goal.dates) > 0 ? 'inline' : 'none',
                          }}
                        >
                          {calculateStreak(goal.dates)} day streak
                        </Typography>
                      </Box>
                    ) : (
                      goal.goalDate && (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                            {calculateDaysUntil(goal.goalDate)} days left
                          </Typography>
                        </Box>
                      )
                    )
                  }
                />
              </ListItem>
            </Box>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <ComponentWrapper sx={{ padding: 2 }}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderGoalList("daily")}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderGoalList("long")}
          </Grid>
        </Grid>
        {canEdit && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setEditingGoal(null);
              setFormOpen(true);
            }}
            sx={{ mt: 2 }}
          >
            Add Goal
          </Button>
        )}
      </Box>
      <Form
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
          setEditingGoal(null);
        }}
        fields={fields}
        onSubmit={
          editingGoal
            ? (data) => handleUpdateGoal(editingGoal.id, data)
            : handleCreateGoal
        }
        title={editingGoal ? "Edit Goal" : "Create Goal"}
        defaultValues={editingGoal || { goalType: "daily" }}
      />
      <AreYouSure
        isOpen={deleteConfirmation.open}
        handleClose={() => setDeleteConfirmation({ open: false, goalId: null })}
        onConfirm={confirmDeleteGoal}
        itemName="this goal"
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditGoal}>Edit</MenuItem>
        <MenuItem onClick={() => handleDeleteGoal(selectedGoal?.id)}>
          Delete
        </MenuItem>
      </Menu>
    </ComponentWrapper>
  );
};

export { CheckGoals };
