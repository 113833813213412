import React from 'react';
import { Box, Typography, Button, useTheme, CssBaseline } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';
import { useLocation } from 'react-router-dom';
import PrivacyPolicy from './privacyPolicy/privacyPolicy';
import { motion } from 'framer-motion';
import { IslandContext } from '../../context/island/islandContext';
import SupportChat from '../../components/supportChat/supportChat';
import FeatureBanner from '../../components/featureBanner/featureBanner';
import FeatureTour from '../../components/featureTour/featureTour';

const PageWrapper = ({ children, title, tabs, rootUrl, sx, ttp = false, policy = true, actions = [], showSupport = true }) => {
  const { token } = useContext(AuthContext);
  const { setTabs, setRootUrl, setActions, setTtp } = useContext(IslandContext);
  const theme = useTheme();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 1000);
  const location = useLocation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
  const [showFeatureTour, setShowFeatureTour] = React.useState(false);
  const [currentFeatureId, setCurrentFeatureId] = React.useState(null);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    setTabs(tabs);
    setRootUrl(rootUrl);
    setActions(actions);
    setTtp(ttp);
  }, [tabs, rootUrl, actions, ttp, setTabs, setRootUrl, setActions, setTtp]);

  React.useEffect(() => {
    if (location.state?.showFeatureTour) {
      setShowFeatureTour(true);
      setCurrentFeatureId(location.state.featureId);
    }
  }, [location]);

  const handleContactUs = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    const id = user?.id || '';
    window.location.href = `mailto:support@tracktoolspro.com?subject=Support Request&body=User Name: ${firstName} ${lastName}%0D%0AUser ID: ${id}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginLeft: token && !isMobile ? '250px' : 0, marginBottom: isMobile ? '64px' : 0, ...sx }}>
      <CssBaseline />
      <FeatureBanner />
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        {!isMobile && (
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom 
            sx={{ 
              p: 0,
              visibility: 'hidden'
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: isMobile ? 0 : 3,
            width: '100%',
            marginBottom: isMobile ? '56px' : 0,
          }}
        >
          <motion.div
            key={tabs ? tabs.findIndex(tab => location.pathname.includes(tab.path)) : 0}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        </Box>
      </Box>
      {showSupport && <SupportChat />}
      {policy && (
      <Box
        component="footer"
        sx={{
          py: 2,
          px: isMobile ? 1 : 2,
          mt: 'auto',
          backgroundColor: theme.palette.background.primary,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleContactUs}
            size="small"
            sx={{ color: 'white', borderColor: 'white' }}
          >
            Contact Us
          </Button>
          <PrivacyPolicy open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />
        </Box>
        <Typography variant="body2" color="white">
          © {new Date().getFullYear()} Track Tools Pro. All rights reserved.
          </Typography>
        </Box>
      )}
      <FeatureTour
        open={showFeatureTour}
        onClose={() => setShowFeatureTour(false)}
        featureId={currentFeatureId}
      />
    </Box>
  );
};

export default PageWrapper;