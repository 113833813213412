import React, { useState } from 'react';
import Form from '../form/form';
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = ({ onSubmit }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const fields = [
    {
      name: 'password',
      label: 'New Password',
      type: 'password',
      required: true,
    },
    {
      name: 'confirmPassword', 
      label: 'Confirm Password',
      type: 'password',
      required: true,
    }
  ];

  const handleClose = () => {
    setOpen(false);
    navigate('/login');
  };

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={onSubmit}
      title="Reset Password"
      sx={{}}
    />
  );
};

export default ResetPasswordForm;