import React from "react";
import { Button } from "@mui/material";
import Form from "../form/form";
// import { useNavigate } from "react-router-dom";

const LoginForm = ({ onSubmit, onSwitchMode, onNavigateHome, onPasswordReset }) => {
  // const navigate = useNavigate();
  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      required: true,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      required: true,
    },
    {
      name: "actions",
      type: "custom",
      render: () => (
        <>
          <Button onClick={onPasswordReset} sx={{ mt: 2, mr: 2 }}>
            Forgot Password?
          </Button>
          <Button onClick={onSwitchMode} sx={{ mt: 2, mr: 2 }}>
            Create Account
          </Button>
          <Button onClick={onNavigateHome} sx={{ mt: 2 }}>
            Home
          </Button>
        </>
      ),
    },
  ];

  const onClose = () => {
    // navigate('/home');
    console.log('onClose');
  };

  return (
    <Form
      open={true}
      onClose={onClose}
      fields={fields}
      onSubmit={onSubmit}
      title="Login"
    />
  );
};

export default LoginForm; 