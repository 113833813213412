import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApiService from "../../api/apiService";
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import CodeIcon from "@mui/icons-material/Code";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import FileUploadForm from "./fileUploadForm";
import { ComponentWrapper } from "../../components/utils";
import AreYouSure from "../../components/utils/areYouSure/areYouSure";

const FileTable = () => {
  const [files, setFiles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState("pod"); // 'pod' or 'table'
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { useTeamFiles, useFileCategories, useDeleteTeamFile } =
    useApiService();

  const { data: teamFiles } = useTeamFiles();
  const { data: fileCategories } = useFileCategories();
  const deleteTeamFile = useDeleteTeamFile();

  useEffect(() => {
    if (teamFiles) setFiles(teamFiles);
    if (fileCategories) setCategories(fileCategories);
  }, [teamFiles, fileCategories]);

  const handleDeleteFile = async () => {
    try {
      await deleteTeamFile.mutateAsync(selectedFile.id);
      setSelectedFile(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Delete file error:", error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleMenuOpen = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
    handleMenuClose();
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleDownload = async (url, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleViewFile = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderFileType = (file) => {
    const fileType = file.name.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png", "gif", "webp"].includes(fileType)) {
      return (
        <CardMedia
          component="img"
          height="120"
          image={file.url}
          alt={file.name}
          loading="lazy"
        />
      );
    } else if (fileType === "pdf") {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <PictureAsPdfIcon sx={{ fontSize: 60, color: "#d32f2f" }} />
        </Box>
      );
    } else if (["mp4", "webm", "ogg", "mov", "avi"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <VideoLibraryIcon sx={{ fontSize: 60, color: "#1976d2" }} />
        </Box>
      );
    } else if (["doc", "docx", "txt", "rtf"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <DescriptionIcon sx={{ fontSize: 60, color: "#2196f3" }} />
        </Box>
      );
    } else if (["xls", "xlsx", "csv"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <TableChartIcon sx={{ fontSize: 60, color: "#4caf50" }} />
        </Box>
      );
    } else if (["ppt", "pptx", "key"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <SlideshowIcon sx={{ fontSize: 60, color: "#ff9800" }} />
        </Box>
      );
    } else if (["mp3", "wav", "aac"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <AudioFileIcon sx={{ fontSize: 60, color: "#9c27b0" }} />
        </Box>
      );
    } else if (
      ["js", "jsx", "ts", "tsx", "html", "css", "py", "java", "cpp"].includes(
        fileType
      )
    ) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <CodeIcon sx={{ fontSize: 60, color: "#607d8b" }} />
        </Box>
      );
    } else if (["zip", "rar", "7z", "tar", "gz"].includes(fileType)) {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <FolderZipIcon sx={{ fontSize: 60, color: "#795548" }} />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "grey.200",
          }}
        >
          <Typography variant="h6" sx={{ color: "black" }}>
            {fileType.toUpperCase()}
          </Typography>
        </Box>
      );
    }
  };

  const filteredFiles = selectedCategory
    ? files.filter((file) => file.fileCategoryId === selectedCategory)
    : files;

  const searchFilteredFiles = filteredFiles.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (categories.length === 0) {
    return (
      <ComponentWrapper sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Create your first file category to get started
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/team-file/categories")}
        >
          Create File Category
        </Button>
      </ComponentWrapper>
    );
  }

  if (files.length === 0) {
    return (
      <ComponentWrapper sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Create your first file
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsUploadModalOpen(true)}
          startIcon={<AddIcon />}
        >
          Upload Files
        </Button>
        <FileUploadForm
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
        />
      </ComponentWrapper>
    );
  }

  return (
    <ComponentWrapper sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box p={2} display="flex" flexDirection="column" alignItems="stretch">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                gap: 2,
              }}
            >
              <TextField
                label="Search Files by Name"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
                size="small"
              >
                <ToggleButton value="pod" aria-label="pod view">
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table view">
                  <ViewListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsUploadModalOpen(true)}
                startIcon={<AddIcon />}
                sx={{
                  ...(isMobile && { fontSize: "0.5rem" }),
                }}
              >
                Upload Files
              </Button>
            </Box>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <MenuItem value="">All Categories</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {viewMode === "pod" ? (
            <Grid container spacing={2}>
              {searchFilteredFiles.map((file) => (
                <Grid item xs={6} sm={6} md={3} key={file.id}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <Box onClick={() => handleViewFile(file.url)}>
                      {renderFileType(file)}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Tooltip title={file.name}>
                          <Typography variant="caption" component="div" noWrap>
                            {file.name.split(".").slice(0, -1).join(".")}.
                            {file.name.split(".").pop()}
                          </Typography>
                        </Tooltip>
                      </CardContent>
                    </Box>
                    <CardActions>
                      <IconButton
                        size="small"
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="view"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDownload(file.url, file.name)}
                        aria-label="download"
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(event) => handleMenuOpen(event, file)}
                        aria-label="more"
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box sx={{ width: "100%" }}>
              {searchFilteredFiles.map((file) => (
                <Card key={file.id} sx={{ mb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewFile(file.url)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Tooltip title={file.name}>
                        <Typography
                          variant="body1"
                          noWrap
                          sx={{ maxWidth: "300px" }}
                        >
                          {file.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="small"
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDownload(file.url, file.name)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(event) => handleMenuOpen(event, file)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>

      <FileUploadForm
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      <AreYouSure
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteFile}
        itemName={selectedFile ? selectedFile.name : ""}
      />
    </ComponentWrapper>
  );
};

export default FileTable;
