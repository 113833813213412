import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import useApiService from "../../api/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatedGridBG } from "../../components/magicUI/animatedGridBG";
import PageWrapper from "../pageWrapper/pageWrapper";
import LoginForm from "../../components/auth/loginForm";
import SignupForm from "../../components/auth/signupForm";
import PasswordResetForm from "../../components/auth/passwordResetForm";

const LogInSignUpPage = () => {
  const { login } = useContext(AuthContext);
  const apiService = useApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateAccount = location.pathname === "/login/create-account";
  const [isLogin, setIsLogin] = useState(!isCreateAccount);
  const [userType, setUserType] = useState(null);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const { mutate: loginUser } = apiService.useLoginUser();
  const { mutate: registerUser } = apiService.useRegisterUser();
  const { mutate: generatePasswordResetToken } = apiService.useGeneratePasswordResetToken();

  const handleSwitchMode = () => {
    setIsLogin(!isLogin);
    setUserType(null);
    navigate(isLogin ? "/login/create-account" : "/login");
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleUserTypeChange = (event, newType) => {
    setUserType(newType);
  };

  const handleLogin = async (formData) => {
    loginUser(
      { email: formData.email, password: formData.password },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;
          
          try {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            localStorage.setItem('userType', type);
            localStorage.setItem('team', JSON.stringify(team));
          } catch (error) {
            console.error('Error storing data in localStorage:', error);
          }

          navigate('/home');
          login(user, token, type, team);
        },
      }
    );
  };

  const handleSignUp = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      console.log("Passwords do not match");
      return;
    }
    registerUser(
      {
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        team_name: formData.teamName,
        user_type: userType,
      },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;

          try {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            localStorage.setItem('userType', type);
            localStorage.setItem('team', JSON.stringify(team));
          } catch (error) {
            console.error('Error storing data in localStorage:', error);
          }

          navigate('/home');
          login(user, token, type, team);
        },
      }
    );
  };

  const handlePasswordReset = (formData) => {
    generatePasswordResetToken(
      { email: formData.email },
      {
        onSuccess: () => {
          setIsPasswordReset(false);
          setIsLogin(true);
        },
      }
    );
  };

  return (
    <PageWrapper ttp={true} tabs={[]} sx={{ m: 0, p: 0 }} policy={false}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", position: "relative" }}
      >
        <AnimatedGridBG />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{ mx: "auto", mt: 10, position: "relative", zIndex: 1 }}
        >
          {isPasswordReset ? (
            <PasswordResetForm
              onSubmit={handlePasswordReset}
              onCancel={() => setIsPasswordReset(false)}
            />
          ) : isLogin ? (
            <LoginForm
              onSubmit={handleLogin}
              onSwitchMode={handleSwitchMode}
              onNavigateHome={handleNavigateHome}
              onPasswordReset={() => setIsPasswordReset(true)}
            />
          ) : (
            <SignupForm
              onSubmit={handleSignUp}
              onSwitchMode={handleSwitchMode}
              onNavigateHome={handleNavigateHome}
              userType={userType}
              onUserTypeChange={handleUserTypeChange}
            />
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default LogInSignUpPage;
