import React from "react";
import { Button, Typography } from "@mui/material";
import Form from "../form/form";
import { useNavigate } from "react-router-dom";

const PasswordResetForm = ({ onSubmit, onCancel }) => {
  const navigate = useNavigate();
  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      required: true,
    },
    {
      name: "emailNote",
      type: "custom",
      render: () => (
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
          The reset email may take a minute to arrive. Please check your spam
          folder if you don't see it.
        </Typography>
      ),
    },
    {
      name: "actions",
      type: "custom",
      render: () => (
        <Button onClick={onCancel} sx={{ mt: 2 }}>
          Cancel
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <Form
      open={true}
      onClose={handleClose}
      fields={fields}
      onSubmit={onSubmit}
      title="Reset Password"
    />
  );
};

export default PasswordResetForm;
