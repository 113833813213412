import React from 'react';
import { Grid } from "@mui/material";
import { useSearchParams, useNavigate } from 'react-router-dom';
import useApiService from "../../api/apiService";
import { AnimatedGridBG } from "../../components/magicUI/animatedGridBG";
import PageWrapper from "../pageWrapper/pageWrapper";
import ResetPasswordForm from "../../components/auth/resetPasswordForm";
import { toast } from 'sonner';

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const apiService = useApiService();
  const { mutate: resetPassword } = apiService.usePasswordReset();

  const handleResetPassword = (formData) => {
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    resetPassword(
      { 
        token: token,
        password: formData.password,
        password_confirmation: formData.confirmPassword
      },
      {
        onSuccess: () => {
          navigate('/login');
        }
      }
    );
  };

  return (
    <PageWrapper ttp={true} tabs={[]} sx={{ m: 0, p: 0 }} policy={false}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", position: "relative" }}
      >
        <AnimatedGridBG />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{ mx: "auto", mt: 10, position: "relative", zIndex: 1 }}
        >
          <ResetPasswordForm onSubmit={handleResetPassword} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default ResetPasswordPage; 