import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  TextField,
  Autocomplete,
  useMediaQuery,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Map, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  Marker,
} from "react-map-gl";
import PageWrapper from "../pageWrapper/pageWrapper";
import MapIcon from "@mui/icons-material/Map";
import SatelliteIcon from "@mui/icons-material/Satellite";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NavigateIcon from "@mui/icons-material/Navigation";
import Route from "./components/route";
import Pin from "./components/pin";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Form from "../../components/form/form";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const EXAMPLE_ROUTES = [
  {
    name: "Hayward Mile",
    locationType: "route",
    coordinates: [
      [-123.07129886293079, 44.04283295706611],
      [-123.07131768272902, 44.042422604708435],
      [-123.07131768272902, 44.041917551751],
      [-123.07120985700359, 44.04170149533665],
      [-123.07101656595628, 44.041568759207555],
      [-123.07077190857858, 44.04150013633554],
      [-123.07044569874188, 44.04155679700261],
      [-123.07025750075887, 44.041803250115625],
      [-123.07020104136419, 44.0420663624451],
      [-123.07020104136419, 44.04247220789179],
      [-123.07021358789635, 44.04267512957284],
      [-123.06995992459144, 44.0427427699787],
      [-123.06977408701196, 44.04273375126243],
      [-123.06972390088336, 44.04310351750547],
      [-123.0697364474155, 44.04332447428098],
      [-123.07001284338116, 44.04342367909544],
      [-123.07064016999061, 44.04342367909544],
      [-123.07136786885762, 44.043414660482824],
      [-123.07203283506388, 44.043369567399054],
      [-123.07255978941573, 44.04334702084407],
      [-123.07311183683225, 44.04332447428098],
      [-123.07354469219261, 44.04328839976131],
      [-123.07383953569928, 44.043247815901196],
      [-123.07420380204172, 44.04325682563706],
      [-123.07454883167702, 44.04324329767928],
      [-123.07461156433777, 44.042837457514906],
      [-123.0746742969989, 44.042341426871445],
      [-123.0746993900632, 44.0418589203519],
      [-123.07469311679712, 44.04139444774708],
      [-123.07466802373281, 44.04113368981339],
      [-123.07420342978415, 44.04126818272002],
      [-123.0738897664796, 44.041430523419564],
      [-123.0737705744238, 44.0415612975483],
      [-123.07373293482697, 44.041701090263246],
      [-123.0737015684966, 44.041904014585526],
      [-123.07301150922605, 44.04194008994767],
      [-123.07268529938935, 44.04194008994767],
      [-123.07244064201162, 44.04209791939962],
      [-123.07243436874522, 44.04227378600734],
      [-123.07228381035904, 44.04241808642408],
      [-123.07227126382687, 44.04259395208189],
      [-123.07216461830322, 44.04282392946129],
      [-123.0718321352001, 44.04307194328223],
      [-123.07154356495985, 44.04322075107672],
      [-123.07119853532455, 44.04328388154295],
      [-123.07053356911868, 44.04335152125378],
      [-123.07013208008834, 44.04327937222638],
      [-123.07001288803255, 44.043112527263304],
      [-123.07001288803255, 44.0428464762154],
      [-123.07027636520882, 44.042819420109794],
      [-123.07057748198121, 44.04288706035092],
      [-123.07089741855219, 44.04296822853806],
      [-123.07112362838888, 44.04296822853806],
      [-123.07131182637188, 44.04282843881302],
    ],
    markerDistance: 100,
    markerUnit: "meters",
    teamId: null,
    id: 1730604432512,
  },
];

const LocationPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mapRef = useRef(null);
  const draw = useRef(null);

  const {
    useTeamLocations,
    useCreateTeamLocation,
    useUpdateTeamLocation,
    useDeleteTeamLocation,
  } = useApiService();
  const { mutate: createTeamLocation } = useCreateTeamLocation();
  const { mutate: updateTeamLocation } = useUpdateTeamLocation();
  const { mutate: deleteTeamLocation } = useDeleteTeamLocation();
  const { data: teamLocations } = useTeamLocations();

  // Map configuration
  const maptilerApiKey = "ZtyUKbxk81MUQm6POXQm";
  const satelliteStyle = `https://api.maptiler.com/maps/hybrid/style.json?key=${maptilerApiKey}`;
  const streetStyle = `https://api.maptiler.com/maps/streets/style.json?key=${maptilerApiKey}`;
  const [mapStyle, setMapStyle] = useState(streetStyle);

  const { user } = useContext(AuthContext);

  const [viewState, setViewState] = useState({
    longitude: user ? -98.007 : -123.0713,
    latitude: user ? 46.9233 : 44.0428,
    zoom: isMobile ? 15 : 17,
  });

  const [guestLocations, setGuestLocations] = useState(() => {
    const saved = sessionStorage.getItem("guestLocations");
    return saved ? JSON.parse(saved) : [];
  });

  const [locations, setLocations] = useState(() => {
    if (user) {
      const saved = sessionStorage.getItem("guestLocations");
      return saved ? JSON.parse(saved) : [];
    }
    return EXAMPLE_ROUTES;
  });

  // Location menu state
  const [locationMenuAnchor, setLocationMenuAnchor] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Show/Hide locations
  const [showLocations, setShowLocations] = useState(true);

  // Search state
  const [searchValue, setSearchValue] = useState(null);

  // Form states
  const [routeFormOpen, setRouteFormOpen] = useState(false);
  const [pinFormOpen, setPinFormOpen] = useState(false);
  const [currentRouteDistance, setCurrentRouteDistance] = useState(null);
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);

  // Drawing control state
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSelectingPinLocation, setIsSelectingPinLocation] = useState(false);

  // Add these new state variables near the other state declarations
  const [showUserLocation, setShowUserLocation] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  // Add new state for watch ID
  const [locationWatchId, setLocationWatchId] = useState(null);

  const handleLocationMenuOpen = (event, location, index) => {
    setLocationMenuAnchor(event.currentTarget);
    setSelectedLocation({ ...location, index });
  };

  const handleLocationMenuClose = () => {
    setLocationMenuAnchor(null);
    setSelectedLocation(null);
  };

  const handleDeleteLocation = () => {
    if (selectedLocation) {
      deleteTeamLocation(selectedLocation.id);
    }
    handleLocationMenuClose();
  };

  const handleEditLocation = () => {
    if (selectedLocation) {
      setEditingLocation(selectedLocation.index);
      if (selectedLocation.locationType === "route") {
        setCurrentCoordinates(selectedLocation.coordinates);
        setRouteFormOpen(true);
      } else {
        setPinFormOpen(true);
      }
    }
    handleLocationMenuClose();
  };

  const handleFlyToLocation = () => {
    if (selectedLocation) {
      const coords =
        selectedLocation.locationType === "route"
          ? selectedLocation.coordinates[0]
          : selectedLocation.coordinates;

      mapRef.current?.getMap().flyTo({
        center: [coords[0], coords[1]],
        zoom: 16,
        duration: 2000,
        animate: true,
        essential: true,
      });
    }
    handleLocationMenuClose();
  };

  // Calculate distance between two points using Haversine formula
  const calculateDistance = (coord1, coord2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = (coord1[1] * Math.PI) / 180;
    const φ2 = (coord2[1] * Math.PI) / 180;
    const Δφ = ((coord2[1] - coord1[1]) * Math.PI) / 180;
    const Δλ = ((coord2[0] - coord1[0]) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  };

  // Calculate total distance of a route
  const calculateTotalDistance = (coordinates) => {
    let total = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      total += calculateDistance(coordinates[i], coordinates[i + 1]);
    }
    return total;
  };

  // Initialize Mapbox Draw
  const initializeDrawControls = () => {
    if (!draw.current && mapRef.current && mapRef.current.getMap()) {
      const map = mapRef.current.getMap();

      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          line_string: true,
          point: true,
          trash: true,
        },
        defaultMode: "simple_select",
      });

      map.addControl(draw.current);

      map.on("draw.create", handleDrawCreate);
      map.on("draw.update", handleDrawUpdate);
      map.on("draw.selectionchange", handleDrawUpdate);
      map.on("draw.modechange", handleDrawUpdate);
      map.on("draw.render", handleDrawUpdate);
    }
  };

  // Clean up Mapbox Draw controls
  const cleanupDrawControls = () => {
    if (mapRef.current && mapRef.current.getMap() && draw.current) {
      const map = mapRef.current.getMap();
      map.off("draw.create", handleDrawCreate);
      map.off("draw.update", handleDrawUpdate);
      map.off("draw.selectionchange", handleDrawUpdate);
      map.off("draw.modechange", handleDrawUpdate);
      map.off("draw.render", handleDrawUpdate);
      map.removeControl(draw.current);
      draw.current = null;
    }
  };

  useEffect(() => {
    return () => {
      cleanupDrawControls();
    };
    // eslint-disable-next-line
  }, []);

  const handleDrawCreate = (e) => {
    const { features } = e;
    if (features.length > 0) {
      const geometry = features[0].geometry;
      if (geometry.type === "LineString") {
        const distance = calculateTotalDistance(geometry.coordinates);
        const distanceInMiles = distance / 1609.34;

        if (distanceInMiles > 30) {
          alert("Route cannot be longer than 30 miles");
          draw.current.deleteAll();
          return;
        }

        setCurrentCoordinates(geometry.coordinates);
        setCurrentRouteDistance(distance);
        setRouteFormOpen(true);
      } else if (geometry.type === "Point") {
        const coordinates = geometry.coordinates;
        setViewState({
          ...viewState,
          longitude: coordinates[0],
          latitude: coordinates[1],
        });
        setPinFormOpen(true);
        setIsSelectingPinLocation(false);
        draw.current.deleteAll();
      }
    }
  };

  const handleDrawUpdate = () => {
    if (!draw.current) return;

    const features = draw.current.getAll().features;
    if (features.length > 0) {
      const coords = features[0].geometry.coordinates;
      if (coords.length > 1) {
        const distance = calculateTotalDistance(coords);
        const distanceInMiles = distance / 1609.34;

        if (distanceInMiles > 30) {
          alert("Route cannot be longer than 30 miles");
          draw.current.deleteAll();
          return;
        }

        setCurrentRouteDistance(distance);
      }
    }
  };

  const handleMapClick = (event) => {
    if (!isSelectingPinLocation) return;

    let coordinates;
    if (event.lngLat) {
      coordinates = event.lngLat;
    } else if (event.point) {
      coordinates = mapRef.current.getMap().unproject(event.point);
    } else if (event.features && event.features[0]) {
      coordinates = event.features[0].geometry.coordinates;
    }

    if (coordinates) {
      setViewState({
        ...viewState,
        longitude: coordinates.lng || coordinates[0],
        latitude: coordinates.lat || coordinates[1],
      });
      setPinFormOpen(true);
      setIsSelectingPinLocation(false);
      mapRef.current.getMap().getCanvas().style.cursor = "grab";
    }
  };

  const handleStyleChange = (event, newStyle) => {
    if (newStyle !== null) {
      setMapStyle(newStyle === "street" ? streetStyle : satelliteStyle);
    }
  };

  const handleRouteFormSubmit = (formData) => {
    const locationData = {
      name: formData.name,
      locationType: "route",
      coordinates: currentCoordinates,
      markerDistance: parseFloat(formData.markerDistance),
      markerUnit: formData.markerUnit,
      teamId: user ? 1 : null,
    };

    if (editingLocation !== null) {
      if (user) {
        updateTeamLocation({
          id: locations[editingLocation].id,
          ...locationData,
        });
      } else {
        const newLocations = [...guestLocations];
        newLocations[editingLocation] = {
          ...locationData,
          id: locations[editingLocation].id,
        };
        setGuestLocations(newLocations);
        sessionStorage.setItem("guestLocations", JSON.stringify(newLocations));
      }
      setEditingLocation(null);
    } else {
      if (user) {
        createTeamLocation(locationData);
      } else {
        if (!handleGuestLocationCreate(locationData)) {
          return;
        }
      }
    }

    if (draw.current) {
      draw.current.deleteAll();
    }
    setCurrentRouteDistance(null);
    setCurrentCoordinates(null);
    setRouteFormOpen(false);
  };

  const handlePinFormSubmit = (formData) => {
    const locationData = {
      name: formData.name,
      locationType: "pin",
      coordinates: [viewState.longitude, viewState.latitude],
      teamId: user ? 1 : null,
    };

    if (editingLocation !== null) {
      if (user) {
        updateTeamLocation({
          id: locations[editingLocation].id,
          ...locationData,
        });
      } else {
        const newLocations = [...guestLocations];
        newLocations[editingLocation] = {
          ...locationData,
          id: locations[editingLocation].id,
        };
        setGuestLocations(newLocations);
        sessionStorage.setItem("guestLocations", JSON.stringify(newLocations));
      }
      setEditingLocation(null);
    } else {
      createTeamLocation(locationData);
    }
    setPinFormOpen(false);
  };

  const handleStartDrawing = () => {
    if (isMobile) {
      alert("Route creation is only available on desktop");
      return;
    }

    if (!draw.current) {
      console.warn("Drawing control not initialized");
      return;
    }

    if (!isDrawing) {
      draw.current.changeMode("draw_line_string");
      setIsDrawing(true);
    } else {
      draw.current.changeMode("simple_select");
      setIsDrawing(false);
      setCurrentRouteDistance(null);
    }
  };

  const handleStartPinPlacement = () => {
    if (!draw.current) {
      console.warn("Drawing control not initialized");
      return;
    }

    if (!isSelectingPinLocation) {
      draw.current.changeMode("draw_point");
      setIsSelectingPinLocation(true);
      mapRef.current.getMap().getCanvas().style.cursor = "crosshair";
    } else {
      draw.current.changeMode("simple_select");
      setIsSelectingPinLocation(false);
      mapRef.current.getMap().getCanvas().style.cursor = "grab";
    }
  };

  const handleSearchChange = (event, newValue) => {
    setSearchValue(newValue);
    if (newValue) {
      const locationIndex = locations.findIndex((l) => l.name === newValue);
      if (locationIndex !== -1) {
        const location = locations[locationIndex];
        handleLocationMenuOpen(event, location, locationIndex);
      }
    }
  };

  const routeFormFields = [
    {
      name: "name",
      label: "Route Name",
      type: "text",
      placeholder: "Enter route name",
    },
    {
      name: "markerDistance",
      label: "Marker Distance",
      type: "number",
      placeholder: "Enter the distance interval for markers",
      customField: {
        type: "custom",
        render: () => (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              You can set distance markers along your route to help track your
              progress. For instance, if you specify a marker distance of 400
              meters, a marker will be placed every 400 meters along the route,
              indicating the cumulative distance covered. This feature is
              particularly useful for runners to monitor their progress and
              pace.
            </Typography>
          </Box>
        ),
      },
    },
    {
      name: "markerUnit",
      label: "Marker Distance Unit",
      type: "singleSelect",
      options: [
        { value: "meters", label: "Meters" },
        { value: "miles", label: "Miles" },
      ],
      customField: {
        type: "custom",
        render: () => (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Choose the unit of measurement for the marker distance. You can
              select either meters or miles based on your preference. This will
              determine the intervals at which markers are placed along your
              route.
            </Typography>
          </Box>
        ),
      },
    },
  ];

  const pinFormFields = [
    {
      name: "name",
      label: "Pin Name",
      type: "text",
      placeholder: "Enter pin name",
    },
  ];

  const tabs = [{ label: "Locations", path: "/locations" }];

  // Modify the existing locations state to use either guest or team locations
  useEffect(() => {
    if (user && teamLocations) {
      setLocations(teamLocations);
    } else if (!user) {
      setLocations(EXAMPLE_ROUTES);
    } else {
      setLocations(guestLocations);
    }
  }, [user, teamLocations, guestLocations]);

  // Add guest location handling
  const handleGuestLocationCreate = (locationData) => {
    if (guestLocations.length >= 3) {
      alert(
        "Create an account to save more locations! Free accounts can create unlimited routes and pins."
      );
      return false;
    }

    const newLocations = [
      ...guestLocations,
      { ...locationData, id: Date.now() },
    ];
    setGuestLocations(newLocations);
    sessionStorage.setItem("guestLocations", JSON.stringify(newLocations));
    return true;
  };

  // Add SEO content for non-authenticated users
  const renderGuestContent = () => {
    if (user) return null;

    return (
      <Box
        sx={{
          my: 10,
          p: 4,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
          mx: "auto",
          textAlign: "center", // Center text
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "2.5rem",
            fontWeight: 700,
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Cross Country Course Measurement & Route Planning Tool
        </Typography>
        <Typography
          variant="h2"
          sx={{ mb: 3, color: "text.secondary", fontSize: "1.25rem" }}
        >
          The ultimate online platform for cross country coaches to measure,
          plan and share training routes with precise distance markers.
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" paragraph>
            Start mapping your cross country courses today - create up to 3
            routes or location pins completely free. No account required.
          </Typography>
          <Typography variant="body1" paragraph>
            Create a free account to unlock premium features designed
            specifically for cross country coaches:
          </Typography>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              p: 0,
              width: "fit-content", // Make list width fit content
              mx: "auto", // Center list
              "& li": {
                display: "flex",
                alignItems: "center",
                mb: 1,
                "&::before": {
                  content: '"✓"',
                  mr: 2,
                  color: "primary.main",
                  fontWeight: "bold",
                },
              },
            }}
          >
            <li>Unlimited routes and pins</li>
            <li>Precise distance markers in miles or meters</li>
            <li>Export routes to Google Maps for on the fly navigation</li>
            <li>Training schedule integration for streamlined communication</li>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {" "}
          {/* Center button */}
          <Button
            variant="contained"
            color="primary"
            href="/login/create-account"
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1.1rem",
            }}
          >
            Start Mapping Your Courses - Free
          </Button>
        </Box>
      </Box>
    );
  };

  // Modify the UserLocationMarker component
  const UserLocationMarker = () => {
    if (!showUserLocation || !userLocation) return null;

    return (
      <Marker
        longitude={userLocation.longitude}
        latitude={userLocation.latitude}
        anchor="center"
      >
        <Box
          sx={{
            position: "relative",
            width: 0, // Set to 0 to ensure proper centering
            height: 0,
          }}
        >
          {/* Inner dot */}
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "16px",
              height: "16px",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "50%",
              border: "3px solid white",
              boxShadow: "0 0 0 2px rgba(0,0,0,0.1)",
              transform: "translate(-50%, -50%)",
              animation: "bounce 1s infinite",
              "@keyframes bounce": {
                "0%, 100%": {
                  transform: "translate(-50%, -50%) scale(1)",
                },
                "50%": {
                  transform: "translate(-50%, -50%) scale(1.1)",
                },
              },
            }}
          />
          {/* Outer ripple */}
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: `${theme.palette.primary.main}33`,
              transform: "translate(-50%, -50%)",
              animation: "ripple 2s infinite ease-out",
              "@keyframes ripple": {
                "0%": {
                  transform: "translate(-50%, -50%) scale(0.5)",
                  opacity: 1,
                },
                "100%": {
                  transform: "translate(-50%, -50%) scale(2)",
                  opacity: 0,
                },
              },
            }}
          />
        </Box>
      </Marker>
    );
  };

  // Modify the handleGetUserLocation function to toggle location tracking
  const handleGetUserLocation = () => {
    if (showUserLocation) {
      setShowUserLocation(false);
      if (locationWatchId !== null) {
        navigator.geolocation.clearWatch(locationWatchId);
        setLocationWatchId(null);
      }
      return;
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          };
          setUserLocation(newLocation);
          setShowUserLocation(true);

          // Use flyTo for smooth transition
          mapRef.current?.getMap().flyTo({
            center: [newLocation.longitude, newLocation.latitude],
            zoom: 16,
            duration: 2000,
            animate: true,
            essential: true,
          });

          // Set up continuous location tracking
          const watchId = navigator.geolocation.watchPosition(
            (position) => {
              setUserLocation({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
              });
            },
            (error) => {
              console.error("Error watching location:", error);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );

          setLocationWatchId(watchId);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(
            "Unable to get your location. Please check your browser permissions."
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      alert("Geolocation is not supported by your browser");
    }
  };

  // Add cleanup for location watching
  useEffect(() => {
    return () => {
      if (locationWatchId !== null) {
        navigator.geolocation.clearWatch(locationWatchId);
      }
    };
  }, [locationWatchId]);

  // Add these new control styles
  const controlStyles = {
    button: {
      minWidth: "40px",
      height: "40px",
      backgroundColor: "background.paper",
      color: "text.primary",
      boxShadow: 2,
      border: "1px solid",
      borderColor: "divider",
      "&:hover": {
        backgroundColor: "action.hover",
        transform: "scale(1.05)",
      },
      transition: "all 0.2s ease-in-out",
    },
    controlGroup: {
      backgroundColor: "background.paper",
      borderRadius: 1,
      padding: 0.5,
      boxShadow: 2,
      border: "1px solid",
      borderColor: "divider",
      backdropFilter: "blur(8px)",
    },
  };

  return (
    <PageWrapper rootUrl="/locations" tabs={tabs}>
      {renderGuestContent()}
      <Box sx={{ p: isMobile ? 1 : 2 }}>
        {isDrawing && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              padding: isMobile ? 1 : 2,
              marginBottom: isMobile ? 1 : 2,
              borderRadius: 1,
              textAlign: "center",
            }}
          >
            <Typography variant={isMobile ? "body1" : "h6"}>
              Current Distance:{" "}
              {currentRouteDistance
                ? `${currentRouteDistance.toFixed(0)} m / ${(
                    currentRouteDistance / 1609.34
                  ).toFixed(2)} miles`
                : "Start drawing..."}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: isMobile ? "80vh" : "70vh",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 1,
              padding: "8px",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(18, 18, 18, 0.85)"
                  : "rgba(255, 255, 255, 0.85)",
              backdropFilter: "blur(8px)",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              width: "170px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            }}
          >
            <Autocomplete
              value={searchValue}
              onChange={handleSearchChange}
              options={locations.map((location) => location.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search locations..."
                  variant="outlined"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.05)"
                          : "rgba(255,255,255,0.8)",
                      fontSize: "0.875rem",
                    },
                  }}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                gap: "4px",
                justifyContent: "space-between",
              }}
            >
              <ToggleButtonGroup
                value={mapStyle === streetStyle ? "street" : "satellite"}
                exclusive
                onChange={handleStyleChange}
                size="small"
                sx={{
                  "& .MuiToggleButton-root": {
                    border: `1px solid ${theme.palette.divider}`,
                    padding: "4px",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  },
                }}
              >
                <ToggleButton value="street">
                  <MapIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="satellite">
                  <SatelliteIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>

              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowLocations(!showLocations)}
                sx={{ minWidth: "auto", px: 1 }}
              >
                {showLocations ? "Hide" : "Show"}
              </Button>
            </Box>

            {isDrawing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartDrawing}
                size="small"
                fullWidth
              >
                Save Route
              </Button>
            ) : isSelectingPinLocation ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleStartPinPlacement}
                size="small"
                fullWidth
              >
                Cancel Pin
              </Button>
            ) : (
              <Box sx={{ display: "flex", gap: "4px" }}>
                <Tooltip
                  title={
                    isMobile
                      ? "Route creation is only available on desktop"
                      : ""
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStartDrawing}
                    size="small"
                    sx={{
                      opacity: isMobile ? 0.6 : 1,
                      pointerEvents: isMobile ? "auto" : "auto",
                      flex: 1,
                    }}
                  >
                    Route
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleStartPinPlacement}
                  size="small"
                  sx={{ flex: 1 }}
                >
                  Pin
                </Button>
              </Box>
            )}
          </Box>

          <Map
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            style={{ width: "100%", height: "100%" }}
            mapStyle={mapStyle}
            mapboxAccessToken={maptilerApiKey}
            ref={mapRef}
            onLoad={initializeDrawControls}
            onClick={handleMapClick}
            interactiveLayerIds={["point"]}
          >
            {/* Enhanced Control Group - Top Right */}
            <Box
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                ...controlStyles.controlGroup,
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  const map = mapRef.current?.getMap();
                  if (map) {
                    map.flyTo({
                      zoom: map.getZoom() + 1,
                      duration: 300,
                      essential: true,
                    });
                  }
                }}
                sx={controlStyles.button}
              >
                <ZoomInIcon />
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  const map = mapRef.current?.getMap();
                  if (map) {
                    map.flyTo({
                      zoom: map.getZoom() - 1,
                      duration: 300,
                      essential: true,
                    });
                  }
                }}
                sx={controlStyles.button}
              >
                <ZoomOutIcon />
              </Button>
            </Box>

            {/* Enhanced Control Group - Bottom Right */}
            <Box
              sx={{
                position: "absolute",
                right: 10,
                bottom: 10,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                ...controlStyles.controlGroup,
              }}
            >
              <NavigationControl
                showCompass={true}
                showZoom={false}
                style={{
                  marginRight: "0px",
                  marginBottom: "0px",
                }}
              />

              <FullscreenControl
                style={{
                  marginRight: "0px",
                  marginBottom: "0px",
                }}
              />

              <Tooltip
                title={
                  showUserLocation ? "Hide my location" : "Show my location"
                }
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleGetUserLocation}
                  sx={{
                    ...controlStyles.button,
                    backgroundColor: showUserLocation
                      ? theme.palette.primary.main
                      : "background.paper",
                    color: showUserLocation ? "white" : "text.primary",
                    "&:hover": {
                      backgroundColor: showUserLocation
                        ? theme.palette.primary.dark
                        : "action.hover",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <MyLocationIcon />
                </Button>
              </Tooltip>
            </Box>

            {/* Enhanced Scale Control - Bottom Left */}
            <Box
              sx={{
                position: "absolute",
                left: 10,
                bottom: 10,
                ...controlStyles.controlGroup,
              }}
            >
              <ScaleControl
                unit="imperial"
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                }}
              />
            </Box>

            {/* User Location Marker with enhanced animation */}
            {userLocation && showUserLocation && <UserLocationMarker />}

            {showLocations &&
              locations.map((location, index) => (
                <Box
                  key={index}
                  onClick={(e) => handleLocationMenuOpen(e, location, index)}
                >
                  {location.locationType === "route" ? (
                    <Route
                      name={location.name}
                      coordinates={location.coordinates}
                      markerDistance={location.markerDistance}
                      unitType={location.markerUnit}
                    />
                  ) : (
                    <Pin
                      name={location.name}
                      coordinates={location.coordinates}
                    />
                  )}
                </Box>
              ))}
          </Map>

          <Form
            open={routeFormOpen}
            onClose={() => {
              setRouteFormOpen(false);
              setEditingLocation(null);
              if (draw.current) {
                draw.current.deleteAll();
              }
              setCurrentRouteDistance(null);
              setCurrentCoordinates(null);
            }}
            fields={routeFormFields}
            onSubmit={handleRouteFormSubmit}
            title={editingLocation !== null ? "Edit Route" : "Create New Route"}
            defaultValues={
              editingLocation !== null ? locations[editingLocation] : {}
            }
          />

          <Form
            open={pinFormOpen}
            onClose={() => {
              setPinFormOpen(false);
              setEditingLocation(null);
            }}
            fields={pinFormFields}
            onSubmit={handlePinFormSubmit}
            title={editingLocation !== null ? "Edit Pin" : "Create New Pin"}
            defaultValues={
              editingLocation !== null ? locations[editingLocation] : {}
            }
          />

          <Menu
            anchorEl={locationMenuAnchor}
            open={Boolean(locationMenuAnchor)}
            onClose={handleLocationMenuClose}
          >
            <MenuItem onClick={handleFlyToLocation}>
              <NavigateIcon sx={{ mr: 1 }} /> Fly to Location
            </MenuItem>
            <MenuItem onClick={handleEditLocation}>
              <EditIcon sx={{ mr: 1 }} /> Edit Location
            </MenuItem>
            <MenuItem onClick={handleDeleteLocation}>
              <DeleteIcon sx={{ mr: 1 }} /> Delete Location
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default LocationPage;
