import React, { useMemo } from 'react';
import useApiService from '../../../../../api/apiService';
import Form from '../../../../../components/form/form';

const AddAthleteToWorkoutForm = ({ open, onClose, workoutGroup, onSave }) => {
  const { useAthletes } = useApiService();
  const { data: athletes } = useAthletes();

  // Get already assigned athlete IDs
  const assignedAthleteIds = useMemo(() => 
    workoutGroup?.workouts?.map(workout => workout.athlete?.id) || [],
    [workoutGroup]
  );

  // Filter out already assigned athletes
  const availableAthletes = useMemo(() => {
    if (!athletes) return [];
    return athletes.filter(athlete => !assignedAthleteIds.includes(athlete.id));
  }, [athletes, assignedAthleteIds]);

  const handleSubmit = async (data) => {
    await onSave(data.selectedAthletes);
    onClose();
  };

  const fields = [
    {
      name: "selectedAthletes",
      label: "Select Athletes",
      type: "multiSelect",
      options: availableAthletes ? availableAthletes.map(athlete => ({
        value: athlete.id,
        label: `${athlete.firstName} ${athlete.lastName}`,
      })) : [],
    }
  ];

  return (
    <Form
      open={open}
      onClose={onClose}
      fields={fields}
      onSubmit={handleSubmit}
      title="Add Athletes to Workout"
      defaultValues={{ selectedAthletes: [] }}
    />
  );
};

export default AddAthleteToWorkoutForm; 