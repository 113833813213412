import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Logo from "../../components/sideNav/2.svg";
import { useIsland } from "./islandContext";
import { motion } from "framer-motion";
import { AuthContext } from "../authContext";

const Island = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const tabsRef = useRef(null);
  const [islandWidth, setIslandWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const { token } = useContext(AuthContext);

  const {
    tabs,
    rootUrl,
    actions,
    ttp,
    activeTab,
    setActiveTab,
    showScrollButtons,
    setShowScrollButtons,
  } = useIsland();

  const checkScrollButtons = useCallback(() => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setShowScrollButtons(scrollWidth > clientWidth);
    }
  }, [setShowScrollButtons]);

  const updateIslandWidth = useCallback(() => {
    if (tabsRef.current) {
      const newWidth =
        tabsRef.current.scrollWidth +
        (ttp ? 180 : 0) +
        (showScrollButtons ? 80 : 0) +
        (actions?.length > 0 ? actions.length * 40 : 0) +
        60;
      setIslandWidth(Math.max(newWidth, 150));
    }
    // eslint-disable-next-line
  }, [actions, ttp]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    navigate(`${rootUrl}/${tabs[index].path}`);
  };

  React.useLayoutEffect(() => {
    if (tabs?.length > 0) {
      if (location.pathname === rootUrl) {
        navigate(`${rootUrl}/${tabs[0].path}`);
      }
      const index = tabs.findIndex((tab) =>
        location.pathname.includes(tab.path)
      );
      setActiveTab(index >= 0 ? index : 0);
    }
    checkScrollButtons();
    updateIslandWidth();
  }, [
    tabs,
    location.pathname,
    rootUrl,
    navigate,
    setActiveTab,
    checkScrollButtons,
    updateIslandWidth,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "center",
        position: "sticky",
        top: 16,
        zIndex: 10,
        ml: !isMobile && token ? "250px" : 0,
      }}
    >
      <motion.div
        style={{
          display: "flex",
          borderRadius: "24px",
          overflow: "hidden",
          backgroundColor: theme.palette.background.dark,
          WebkitOverflowScrolling: "touch",
        }}
        initial={{ width: 0 }}
        animate={{ width: islandWidth }}
        transition={{ duration: 0.3 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent:
              ttp && !tabs?.length && !actions?.length
                ? "center"
                : "flex-start",
            alignItems: "center",
            width: "100%",
            px: 1,
          }}
        >
          {ttp && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 1,
                mr: 2,
                minWidth: "80px",
              }}
            >
              <img
                src={Logo}
                alt="Track Tools Pro"
                style={{ height: "30px", maxWidth: "100%" }}
              />
            </Box>
          )}
          <Box
            ref={tabsRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              justifyContent: "flex-start",
              height: "44px",
              px: 1,
            }}
          >
            {tabs?.map((tab, index) => (
              <Button
                key={index}
                onClick={() => handleTabChange(index)}
                sx={{
                  py: 0.5,
                  px: 1.5,
                  color:
                    activeTab === index
                      ? theme.palette.primary.contrastText
                      : "white",
                  backgroundColor:
                    activeTab === index
                      ? theme.palette.primary.main
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      activeTab === index
                        ? theme.palette.primary.dark
                        : theme.palette.action.hover,
                  },
                  transition: "all 0.3s ease",
                  whiteSpace: "nowrap",
                  borderRadius: "16px",
                  margin: "4px 2px",
                  minHeight: "36px",
                  fontSize: "0.875rem",
                }}
                disabled={tab.disabled}
              >
                {tab.label}
              </Button>
            ))}
          </Box>
          {actions?.length > 0 && (
            <Box
              sx={{ display: "flex", alignItems: "center", ml: "auto", mr: 1 }}
            >
              {actions.map((action, index) => (
                <IconButton
                  key={index}
                  onClick={action.action}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                    transition: "all 0.3s ease",
                    ml: 0.5,
                  }}
                >
                  {action.label === "+" ? <AddIcon /> : action.label}
                </IconButton>
              ))}
            </Box>
          )}
          {ttp && !token && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => navigate("/login")}
              sx={{
                ml: "auto",
                mr: 1,
                borderRadius: "16px",
                fontSize: "0.6rem",
              }}
            >
              Sign In
            </Button>
          )}
        </Box>
      </motion.div>
    </Box>
  );
};

export default Island;
