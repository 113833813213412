import React from 'react';
import { 
  Dialog, 
  DialogContent, 
  Button, 
  MobileStepper, 
  Typography, 
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { 
  KeyboardArrowLeft, 
  KeyboardArrowRight,
  Close as CloseIcon 
} from '@mui/icons-material';
import { getTourSteps } from '../../constants/featureTours';

const FeatureTour = ({ open, onClose, featureId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getTourSteps(featureId);
  const maxSteps = steps.length;

  React.useEffect(() => {
    setActiveStep(0);
  }, [featureId]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleComplete = () => {
    const completedFeatures = JSON.parse(localStorage.getItem('completedFeatures') || '[]');
    if (!completedFeatures.includes(featureId)) {
      completedFeatures.push(featureId);
      localStorage.setItem('completedFeatures', JSON.stringify(completedFeatures));
    }
    
    const reminders = JSON.parse(localStorage.getItem('feature_reminders') || '{}');
    delete reminders[featureId];
    localStorage.setItem('feature_reminders', JSON.stringify(reminders));
    
    onClose();
  };

  const handleClose = () => {
    // Schedule reminder for tomorrow at 9 AM when tour is not completed
    const reminders = JSON.parse(localStorage.getItem('feature_reminders') || '{}');
    const reminderTime = new Date();
    reminderTime.setDate(reminderTime.getDate() + 1);
    reminderTime.setHours(9, 0, 0, 0);
    
    reminders[featureId] = reminderTime.getTime();
    localStorage.setItem('feature_reminders', JSON.stringify(reminders));
    
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          ...(isMobile && {
            margin: 2,
            width: 'calc(100% - 32px)',
            maxHeight: 'calc(100% - 32px)',
          }),
        }
      }}
    >
      <Box sx={{ 
        position: 'relative',
        bgcolor: 'background.paper',
        borderRadius: 2,
      }}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.secondary',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ 
            p: 3, 
            pb: 2,
            textAlign: 'center',
            borderBottom: 1,
            borderColor: 'divider',
          }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 1,
                fontWeight: 600,
                color: 'primary.main',
              }}
            >
              {steps[activeStep]?.title}
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ 
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.6,
              }}
            >
              {steps[activeStep]?.content}
            </Typography>
          </Box>

          {steps[activeStep]?.image && (
            <Box
              component="img"
              src={steps[activeStep].image}
              alt={steps[activeStep].title}
              sx={{ 
                width: '100%', 
                height: 'auto', 
                maxHeight: 400, 
                objectFit: 'contain',
                p: 3,
              }}
            />
          )}

          <Box sx={{ 
            p: 2,
            bgcolor: 'background.default',
            borderTop: 1,
            borderColor: 'divider',
          }}>
            <MobileStepper
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                bgcolor: 'transparent',
                '& .MuiMobileStepper-dot': {
                  width: 8,
                  height: 8,
                  mx: 0.5,
                },
                '& .MuiMobileStepper-dotActive': {
                  bgcolor: 'primary.main',
                },
              }}
              nextButton={
                <Button
                  size="large"
                  onClick={activeStep === maxSteps - 1 ? handleComplete : handleNext}
                  sx={{ 
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    ...(activeStep === maxSteps - 1 && {
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      '&:hover': {
                        bgcolor: 'primary.dark',
                      },
                    }),
                  }}
                >
                  {activeStep === maxSteps - 1 ? 'Complete' : 'Next'}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button 
                  size="large"
                  onClick={handleBack} 
                  disabled={activeStep === 0}
                  sx={{ 
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                  }}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default FeatureTour; 