import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import ViewModal from "../viewModal/viewModal";
import { AuthContext } from "../../context/authContext";
import useApiService from "../../api/apiService";

const SupportChat = () => {
  const { user } = useContext(AuthContext);
  const { useCreateInquiry } = useApiService();
  const createInquiry = useCreateInquiry();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    inquiryType: "",
    message: "",
    email: "",
  });

  // Set user's email from AuthContext when component mounts or user changes
  useEffect(() => {
    if (user?.email) {
      setFormData((prev) => ({
        ...prev,
        email: user.email,
      }));
    }
  }, [user]);

  const handleSubmit = () => {
    createInquiry.mutate({
      inquiryType: formData.inquiryType,
      message: formData.message,
      email: formData.email
    });
    // Reset form data to initial state
    setFormData({
      inquiryType: "",
      message: "",
      email: user?.email || "",
    });
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "80px",
        right: 0,
        zIndex: 1000,
        display: "flex",
      }}
    >
      <ViewModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            height: "80vh",
            maxHeight: "600px",
            width: "400px",
            p: 3,
          },
        }}
      >
        <Typography variant="h6" sx={{ mb: 3 }}>
          Support
        </Typography>

        {!formData.inquiryType ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setFormData(prev => ({ ...prev, inquiryType: 'bug_report' }))}
            >
              Report a Bug
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setFormData(prev => ({ ...prev, inquiryType: 'support' }))}
            >
              Contact Support
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setFormData(prev => ({ ...prev, inquiryType: 'feature_request' }))}
            >
              Request a Feature
            </Button>
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle1">
                {formData.inquiryType === 'bug_report' && 'Report a Bug'}
                {formData.inquiryType === 'support' && 'Contact Support'}
                {formData.inquiryType === 'feature_request' && 'Request a Feature'}
              </Typography>
              <Button 
                size="small"
                onClick={() => setFormData(prev => ({ ...prev, inquiryType: '' }))}
              >
                Change
              </Button>
            </Box>

            <TextField
              fullWidth
              multiline
              rows={4}
              name="message"
              label="Message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Type your message here..."
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email address"
              sx={{ mb: 3 }}
            />

            <Button 
              variant="contained" 
              fullWidth 
              onClick={handleSubmit}
              disabled={createInquiry.isLoading}
            >
              {createInquiry.isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </>
        )}
      </ViewModal>

      <Paper
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          cursor: "pointer",
          bgcolor: isOpen ? "transparent" : "primary.main",
          color: "white",
          width: 24,
          height: 120,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.3s ease",
          opacity: isOpen ? 0 : 0.7,
          "&:hover": {
            opacity: 0.9,
            bgcolor: "primary.dark",
          },
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      >
        <Typography
          sx={{
            transform: "rotate(-90deg)",
            whiteSpace: "nowrap",
            fontSize: "0.8rem",
            letterSpacing: "1px",
            fontWeight: 500,
          }}
        >
          SUPPORT
        </Typography>
      </Paper>
    </Box>
  );
};

export default SupportChat;
