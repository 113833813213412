import React from "react";
import { Grid, Button, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Form from "../form/form";
// import { useNavigate } from "react-router-dom";

const SignupForm = ({ 
  onSubmit, 
  onSwitchMode, 
  onNavigateHome, 
  userType, 
  onUserTypeChange 
}) => {
  // const navigate = useNavigate();
  const fields = [
    {
      name: "userType",
      type: "custom",
      render: () => (
        <Grid container direction="column" spacing={2} sx={{ mb: 3 }}>
          <Grid item>
            <ToggleButtonGroup
              value={userType}
              exclusive
              onChange={onUserTypeChange}
              aria-label="user type"
            >
              <ToggleButton value="coach">Coach</ToggleButton>
              <ToggleButton value="athlete">Athlete</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {userType === "athlete" && (
            <Grid item>
              <Typography color="error">
                Athletes must be invited by their coach to join. Please contact your coach for an invitation.
              </Typography>
            </Grid>
          )}
        </Grid>
      ),
    },
    userType === "coach" && {
      name: "email",
      label: "Email Address",
      type: "email",
      required: true,
    },
    userType === "coach" && {
      name: "password",
      label: "Password",
      type: "password",
      required: true,
    },
    userType === "coach" && {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      required: true,
    },
    userType === "coach" && {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
    },
    userType === "coach" && {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
    },
    userType === "coach" && {
      name: "teamName",
      label: "Team Name",
      type: "text",
      required: true,
    },
    {
      name: "actions",
      type: "custom",
      render: () => (
        <>
          <Button onClick={onSwitchMode} sx={{ mt: 2, mr: 2 }}>
            Login
          </Button>
          <Button onClick={onNavigateHome} sx={{ mt: 2 }}>
            Home
          </Button>
        </>
      ),
    },
  ].filter(Boolean);

  const onClose = () => {
    // navigate('/home');
    console.log('onClose');
  };

  return (
    <Form
      open={true}
      onClose={onClose}
      fields={fields}
      onSubmit={onSubmit}
      title="Sign Up"
      disabled={userType === "athlete"}
    />
  );
};

export default SignupForm; 