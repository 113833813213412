import React from "react";
import {
  Typography,
  Chip,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Paper,
} from "@mui/material";
import {
  CreditCard,
  People,
  Poll,
  Notifications,
  FileCopy,
  Message,
  DirectionsRun,
  Analytics,
  Timeline,
  WbSunny,
  Speed,
  EmojiEvents,
  Star,
  PostAdd,
  Comment,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const ComingSoon = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState({});

  const features = [
    {
      label: "Create a Team and Add Coaches",
      completed: true,
      icon: People,
    },
    {
      label: "Free and Pro Subscriptions",
      completed: true,
      icon: CreditCard,
      subFeatures: ["Stripe Integration", "Free Tier", "Pro Tier"],
    },
    {
      label: "Training Logs",
      pro: true,
      icon: DirectionsRun,
      subFeatures: [
        "Garmin Integration",
        "Workout Analytics",
        "Track Workout Performance Over Time",
        "Weather Integration",
      ],
    },
  ];

  const getIcon = (subFeature) => {
    const iconMap = {
      "Stripe Integration": CreditCard,
      "Free Tier": People,
      "Pro Tier": Star,
      Forms: FileCopy,
      Polls: Poll,
      Notifications: Notifications,
      Files: FileCopy,
      Messaging: Message,
      "Garmin Integration": DirectionsRun,
      "Workout Analytics": Analytics,
      "Track Workout Performance Over Time": Timeline,
      "Weather Integration": WbSunny,
      VDOT: Speed,
      VVO2: Speed,
      GOALS: Speed,
      Posts: PostAdd,
      Comments: Comment,
    };
    const IconComponent = iconMap[subFeature] || EmojiEvents;
    return <IconComponent fontSize="small" />;
  };

  const handleClick = (index) => {
    setOpen((prevOpen) => ({ ...prevOpen, [index]: !prevOpen[index] }));
  };

  return (
    <Paper
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      elevation={3}
      sx={{
        padding: 4,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paperSecondary,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.primary.main, fontWeight: "bold", mb: 3 }}
      >
        Coming Soon!
      </Typography>
      <List component="nav">
        {features.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <ListItem
              button
              onClick={() => item.subFeatures && handleClick(index)}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: 2,
                mb: 2,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  transform: "translateY(-2px)",
                  transition: "all 0.3s",
                },
                boxShadow: theme.shadows[1],
              }}
            >
              <ListItemIcon>
                <item.icon
                  color={
                    item.completed
                      ? "success"
                      : item.inProgress
                      ? "warning"
                      : "primary"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      textDecoration: item.completed ? "line-through" : "none",
                      color: theme.palette.text.primary,
                      fontWeight: "medium",
                    }}
                  >
                    {item.label}
                  </Typography>
                }
              />
              {item.pro && (
                <Chip
                  label="PRO"
                  size="small"
                  sx={{
                    ml: 1,
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.warning.contrastText,
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  }}
                />
              )}
              {item.inProgress && (
                <Chip
                  label="In Progress"
                  size="small"
                  sx={{
                    ml: 1,
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.info.contrastText,
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  }}
                />
              )}
              {item.subFeatures &&
                (open[index] ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {item.subFeatures && (
              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subFeatures.map((subFeature, subIndex) => (
                    <ListItem
                      key={subIndex}
                      sx={{
                        pl: 4,
                        py: 1,
                        borderLeft: `2px solid ${theme.palette.divider}`,
                        ml: 2,
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        {getIcon(subFeature)}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              textDecoration: [
                                "Posts",
                                "Comments",
                                "GOALS",
                                "Stripe Integration",
                                "Free Tier",
                                "Pro Tier",
                                "Notifications",
                              ].includes(subFeature)
                                ? "line-through"
                                : "none",
                              fontWeight: "medium",
                            }}
                          >
                            {subFeature}
                          </Typography>
                        }
                      />
                      {[
                        "Posts",
                        "Comments",
                        "GOALS",
                        "Stripe Integration",
                        "Free Tier",
                        "Pro Tier",
                        "Notifications",
                        "Files",
                      ].includes(subFeature) && (
                        <Chip
                          label="Done"
                          size="small"
                          sx={{
                            ml: 1,
                            backgroundColor: theme.palette.success.light,
                            color: theme.palette.success.contrastText,
                            fontSize: "0.6rem",
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </motion.div>
        ))}
      </List>
    </Paper>
  );
};

export { ComingSoon };
